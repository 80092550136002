import { api } from "./../funcs/AuthManager";

async function GetPVSList(groupId, page) {
  return api
    .get(`/api/v1/pvs_list/${groupId}/`, { params: { page: page } })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default GetPVSList;
