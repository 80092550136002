import { api } from "./../funcs/AuthManager";
const moment = require("moment");
async function GetVoltage(id, currentDate, dateType) {
  return api
    .get(`/api/v1/phase_data/${id}/`, {
      params: {
        date:
          dateType === "week"
            ? moment(currentDate).weekday(0).format("YYYY-MM-DD")
            : moment(currentDate).format("YYYY-MM-DD"),
        type: dateType,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export default GetVoltage;
