import { useAuth } from "./useAuth";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  const { authed } = useAuth();

  const location = useLocation();

  return JSON.parse(authed) ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
};
