import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import GetWorkOrders from "../api/GetWorkOrders";
import WorkOrdersTable from "../components/WorkOrdersTable";
import CustomCircularProgress from "../components/CustomCircularProgress";
import TaskCard from "../components/TaskCard";
import CustomSnackBar from "../components/CustomSnackBar";
const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height }) => ({
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: height,
    flexGrow: 1,
  })
);

const WorkOrders = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [authFailCode, setAuthFailCode] = useState(0);
  const [workOrdersData, setWorkOrdersData] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [forcedReload, setForcedReload] = useState(false);
  const [pickedRow, setPickedRow] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  useEffect(() => {
    const handleOnLoad = () => {
      setIsLoading(true);
      setHidden(true);
      GetWorkOrders()
        .then((res) => {
          console.log(res.data);
          setWorkOrdersData(res.data);
        })
        .catch((err) => {
          setAuthFailCode(err.response.status);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    handleOnLoad();
  }, [forcedReload]);

  const handleReload = () => {
    setForcedReload((prev) => !prev);
  };
  const handleOpenCard = () => {
    setHidden(false);
  };
  const handleCloseCard = () => {
    setHidden(true);
  };
  const handleTaskData = (value) => {
    setPickedRow(value);
  };

  const handleSelectionModel = (val) => {
    setSelectionModel(val);
  };

  const handleSnackBarText = (val) => {
    setSnackBarText(val);
  };
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  return (
    <StyledGridItem
      item
      container
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{ height: "90vh" }}
    >
      <StyledGridItem
        item
        lg={hidden ? 12 : 8}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        sx={{ padding: "1rem", height: "100%" }}
      >
        {isLoading ? (
          <CustomCircularProgress
            size={120}
            position="absolute"
            top="50%"
            left="50%"
            color={theme.palette.primary.dark}
          />
        ) : (
          workOrdersData && (
            <WorkOrdersTable
              handleOpenCard={handleOpenCard}
              handleTaskData={handleTaskData}
              handleSelectionModel={handleSelectionModel}
              selectionModel={selectionModel}
              workOrdersData={workOrdersData}
            />
          )
        )}
      </StyledGridItem>
      {!hidden && (
        <StyledGridItem
          item
          lg={4}
          md={12}
          sm={12}
          xs={12}
          alignitems="center"
          justifycontent="center"
          sx={{ padding: "1rem" }}
        >
          {isLoading ? (
            <CustomCircularProgress
              size={120}
              position="absolute"
              top="50%"
              left="50%"
              color={theme.palette.primary.dark}
            />
          ) : (
            <TaskCard
              handleOpenSnackBar={handleOpenSnackBar}
              handleSnackBarText={handleSnackBarText}
              handleCloseCard={handleCloseCard}
              handleSelectionModel={handleSelectionModel}
              handleReload={handleReload}
              pickedRow={pickedRow}
            />
          )}
        </StyledGridItem>
      )}
      <CustomSnackBar
        text={snackBarText}
        handleCloseSnackBar={handleCloseSnackBar}
        openSnackBar={openSnackBar}
      />
    </StyledGridItem>
  );
};

export default WorkOrders;
