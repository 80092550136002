import * as React from "react";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { normal } from "../constants/colorConfig";
import { Typography } from "@mui/material";
import PickersDay, {
  PickersDayProps,
  pickersDayClasses,
} from "@mui/lab/PickersDay";
import { pl, enGB } from "date-fns/locale";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  color: theme.palette.primary.tertiary,
}));
const theme = createTheme({
  components: {
    // Name of the component
    MuiYearPicker: {
      defaultProps: {
        // The props to change the default for.
        backgroundColor: "black", // No more ripple!
      },
    },
  },
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiButtonBase-root": {
    color: theme.palette.primary.tertiary,
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.tertiary,
  },
  "& .MuiInputBase-input": {
    color: "black",
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.secondary,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary.secondary_transparent_half,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.secondary,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.primary.secondary_transparent_half,
    fontSize: "1rem",
    fontFamily: ["Epilogue, sans-serif"].join(","),

    "&:hover fieldset": {
      borderBottomColor: theme.palette.primary.secondary_transparent_half,
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: theme.palette.primary.secondary,
    },
  },
}));

export default function YearDatePicker({
  setCurrentDate,
  currentDate,
  minDate,
  maxDate,
}) {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={pl}>
        <StyledDatePicker
          views={["year"]}
          label="Rok"
          clearable={true}
          allowSameDateSelection={true}
          showTodayButton={true}
          minDate={new Date(minDate)}
          maxDate={new Date(maxDate)}
          value={currentDate}
          onChange={(newValue) => {
            setCurrentDate(newValue);
          }}
          renderInput={(params) => (
            <StyledTextField {...params} variant="standard" />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
