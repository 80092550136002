import { api } from "./../funcs/AuthManager";

async function GetLastEvent(pv_id) {
  return api
    .get(`/api/v1/last_event/${pv_id}/`)
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return Promise.reject(error);
    });
}

export default GetLastEvent;
