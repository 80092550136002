import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceLine,
} from "recharts";
import { useTheme } from "@mui/material";
import pl from "date-fns/locale/pl";
import format from "date-fns/format";
import { Typography, Box } from "@mui/material";
import moment from "moment";
import "moment/locale/pl";

moment.locale("pl", {
  week: {
    dow: 8,
  },
});
moment.locale("pl");
const CustomTooltip = ({ active, payload, label, theme, dateType }) => {
  if (active && payload && payload.length) {
    //

    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dark,
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {dateType === "day" ? (
          <Typography variant="h3">
            Godzina:
            <span style={{ fontWeight: "bold" }}>
              {format(new Date(label), "HH:mm")}
            </span>
          </Typography>
        ) : (
          <Typography variant="h3">
            Dzień tygodnia:
            <span style={{ fontWeight: "bold" }}>
              {moment(label).format("ddd HH:mm")}
            </span>
          </Typography>
        )}
        <Typography variant="h3">Napięcie fazowe </Typography>
        {payload.map((entry, index) => {
          return (
            <Typography variant="h3">
              <span style={{ fontWeight: "bold", color: entry.stroke }}>
                {entry.name}: {entry.value} V
              </span>
            </Typography>
          );
        })}
      </Box>
    );
  }

  return null;
};

const VoltageChart = ({ voltageData, dateType }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          domain={["auto", "auto"]}
          interval="preserveStartEnd"
          scale="time"
          type="number"
          tick={{ fill: theme.palette.text.secondary }}
          minTickGap={20}
          allowDuplicatedCategory={false}
          dataKey="x"
          label={{
            fill: theme.palette.text.secondary,
            value: "Godzina",
            angle: 0,
            fontSize: "15",
            offset: "-15 ",
            fontWeight: "300",
            position: "insideBottom",
          }}
          tickFormatter={(value) => {
            //
            //
            if (dateType === "day") {
              return format(new Date(value), "HH:mm");
            }
            if (dateType === "week") {
              return moment(value).format("ddd HH:mm");
            }
          }}
        />
        <YAxis
          domain={["auto", "auto"]}
          type="number"
          tick={{ fill: theme.palette.text.secondary }}
          tickFormatter={(value) => {
            return `${value} V`;
          }}
          label={{
            fill: theme.palette.text.secondary,
            angle: 0,
            fontSize: "15",
            offset: "15",
            fontWeight: "300",
            position: "top",
          }}
        />
        <ReferenceLine
          alwaysShow="true"
          isFront="true"
          y={253}
          strokeWidth="2"
          label={{
            fill: theme.palette.primary.alert,
            value: "253 V",
            position: "insideBottomRight",
          }}
          stroke={theme.palette.primary.alert}
          strokeDasharray="5 5"
        />
        {/* <Legend /> */}
        <Line
          dot={false}
          type="monotone"
          dataKey="y"
          // data={parseReactivePowers(powerData)[0]}
          strokeWidth={1.5}
          // connectNulls
          data={voltageData.phase_a}
          stroke={theme.palette.primary.brown}
          name="Faza 1"
          activeDot={{ r: 8 }}
        />
        <Line
          dot={false}
          type="monotone"
          dataKey="y"
          // data={parseReactivePowers(powerData)[0]}
          strokeWidth={1.5}
          // connectNulls
          stroke={theme.palette.primary.main}
          data={voltageData.phase_b}
          name="Faza 2"
          activeDot={{ r: 8 }}
        />
        <Line
          dot={false}
          type="monotone"
          dataKey="y"
          // connectNulls
          // data={parseReactivePowers(powerData)[0]}
          strokeWidth={1.5}
          stroke={theme.palette.primary.success}
          data={voltageData.phase_c}
          name="Faza 3"
          activeDot={{ r: 8 }}
        />
        <Tooltip
          content={<CustomTooltip dateType={dateType} theme={theme} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default VoltageChart;
