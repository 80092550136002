import { api } from "./../funcs/AuthManager";

async function PatchPv(sn, email) {
  return api
    .patch(`/api/v1/pv_admin/${sn}/`, { user: { email: email } })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default PatchPv;
