import { api } from "./../funcs/AuthManager";

async function PatchTaskOrder(id, status) {
  return api
    .patch(`/api/v1/task_order/${id}/`, {
      status: status,
      update_date: new Date(),
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default PatchTaskOrder;
