import { api } from "./../funcs/AuthManager";

async function ActivationReminder(email) {
  return api
    .post(`/api/v1/activation_reminder/`, { email })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default ActivationReminder;
