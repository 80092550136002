import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { styled, useTheme } from "@mui/material/styles";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import React from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
    padding: "0",
    boxShadow: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-menuIcon": {
    color: theme.palette.text.primary,
    background: "transparent",
  },
}));

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const theme = useTheme();

  return (
    <Pagination
      sx={{
        color: theme.palette.text.primary,
        "& .MuiButtonBase-root": {
          color: theme.palette.text.primary,
          borderColor: theme.palette.text.secondary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        "& .Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.dark,
          "&.Mui-focusVisible": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.dark,
          },
        },
      }}
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const SinglePVTable = ({ data, columns, field }) => {
  const theme = useTheme();
  const [sortModel, setSortModel] = React.useState([
    {
      field: field,
      sort: "desc",
    },
  ]);
  return (
    <Box sx={{ width: "100%", display: "flex", flexGrow: 1 }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          components={{
            Pagination: CustomPagination,
          }}
          density="compact"
          sx={{
            backgroundColor: "transparent",
            border: "none",
            "& .cell": {
              color: theme.palette.text.primary,
              fontSize: "0.8rem",
            },
            "& .alert": {
              color: theme.palette.primary.alert,
              fontSize: "0.8rem",
            },
          }}
          getCellClassName={(params) => {
            if (params.field === "deviation") {
              return "alert";
            } else {
              return "cell";
            }
          }}
          getRowId={(row) => {
            return row.id;
          }}
          hideFooterSelectedRowCount={true}
          pageSize={5}
          rowsPerPageOptions={[5]}
          pagination
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          disableExtendRowFullWidth={false}
          rows={data}
          columns={columns}
          loading={!data}
        />
      </div>
    </Box>
  );
};

export default SinglePVTable;
