import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const StyledBox = styled(Box)(({ theme, flexdirection }) => ({
  display: "flex",
  flexDirection: flexdirection,
}));

const FlowPvsList = ({ userPvs }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {userPvs.map((entry, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${index}-content`}
              id={`panel-${index}-header`}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {entry.description}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {entry.sn}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledBox
                flexdirection="row"
                sx={{ width: "100%", justifyContent: "space-between" }}
              >
                <StyledBox flexdirection="column">
                  <Typography variant="h2">{entry.pv_vendor}</Typography>
                  <Typography variant="h3">
                    {entry.pv_vendor === "Solarman"
                      ? "Zewnętrzny serwis"
                      : "Producent"}
                  </Typography>
                </StyledBox>
                <StyledBox flexdirection="column">
                  <Typography variant="h2">
                    {entry.generator_rated_power / 1000} kWp
                  </Typography>
                  <Typography variant="h3">Moc generatora</Typography>
                </StyledBox>
                <StyledBox flexdirection="column">
                  <Typography variant="h2">
                    {entry.inverter_rated_power / 1000} kWp
                  </Typography>
                  <Typography variant="h3">Moc falownika</Typography>
                </StyledBox>
              </StyledBox>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
export default FlowPvsList;
