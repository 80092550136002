import { api } from "./../funcs/AuthManager";

async function GetAvailableModems() {
  return api
    .get(`/api/v1/available_modems/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default GetAvailableModems;
