import { api } from "./../funcs/AuthManager";

async function GetWeeklyOvervoltages() {
  return api
    .get(`/api/v1/group_overvoltages/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}
export default GetWeeklyOvervoltages;
