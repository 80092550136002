import { api } from "../funcs/AuthManager";

async function GetWorkOrders() {
  return api
    .get(`/api/v1/task_order/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default GetWorkOrders;
