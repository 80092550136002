import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
    // border:'none',
    // borderRadius:'15px',
    padding: "0",
    // background:theme.palette.primary.dark,
    boxShadow: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    // background:theme.palette.primary.dark,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-menuIcon": {
    color: theme.palette.text.primary,
    background: "transparent",
  },
  //   '& .MuiSvgIcon-root':{
  //       color:theme.palette.text.primary,
  //       background:'transparent',
  //   }
}));

const tempData = [
  {
    id: 1,
    group: "Pomorskie",
    pv: "ADVANT 01",
    phase: "24",
    value: "37",
    date: 1645090007000,
  },
];

export default function RecentOvervoltages({ weeklyOvervoltages, isLoading }) {
  //
  const theme = useTheme();
  const navigate = useNavigate();
  const [sortModel, setSortModel] = React.useState([
    {
      field: "dt",
      sort: "desc",
    },
  ]);
  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        sx={{
          color: theme.palette.text.primary,
          "& .MuiButtonBase-root": {
            // backgroundColor:theme.palette.primary.accent,
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.secondary,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.dark,
            "&.Mui-focusVisible": {
              background: theme.palette.primary.main,
              color: theme.palette.primary.dark,
            },
          },
        }}
        // color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };
  const useStyles = makeStyles({
    root: {
      "& .header": {
        color: theme.palette.text.primary,
        //   background:theme.palette.primary.dark,
        "& > .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "& > .MuiDataGrid-menuIcon": {
          // background:theme.palette.primary.dark,
          color: theme.palette.text.primary,
        },
      },
    },
  });
  const classes = useStyles();
  const columns = [
    {
      field: "group_name",
      headerName: "Grupa",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "pv_description",
      headerName: "Instalacja",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "count",
      headerName: "Ilość przekroczeń",
      headerClassName: "header",
      headerAlign: "center",

      align: "center",
      flex: 1,
    },
    {
      field: "dt",
      headerName: "Data",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",

      renderCell: (params: GridRenderCellParams) => {
        //
        return format(new Date(params.formattedValue), "dd/MM/yyyy", {
          locale: pl,
        });
      },
      flex: 1,
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        background: theme.palette.primary.dark,
        flexDirection: "column",
        boxShadow: "0px 10px 20px -5px #000000",
      }}
    >
      <Typography
        variant="h1"
        sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        Ostatnie przekroczenia napięć
      </Typography>
      <div style={{ flexGrow: 1 }}>
        {/* <ThemeProvider theme={myTheme}> */}
        <StyledDataGrid
          components={{
            Pagination: CustomPagination,
          }}
          sx={{
            backgroundColor: "transparent",
            border: "none",
            "& .cell": {
              color: theme.palette.text.primary,
              fontSize: "1rem",
            },
            "& .alert": {
              color: theme.palette.primary.alert,
              fontSize: "1rem",
            },
          }}
          getCellClassName={(params) => {
            if (params.field === "deviation") {
              return "alert";
            } else {
              return "cell";
            }
          }}
          hideFooterSelectedRowCount={true}
          className={classes.root}
          // disableSelectionOnClick={true}
          pageSize={5}
          rowsPerPageOptions={[5]}
          pagination
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          disableExtendRowFullWidth={false}
          rows={weeklyOvervoltages}
          // autoHeight={true}
          onRowClick={(row) => {
            if (row && row.row && row.row.pv_sn) {
              navigate(`/pv/${row.row.pv_sn}`);
            }
          }}
          columns={columns}
          loading={isLoading}
        />
        {/* </ThemeProvider> */}
      </div>
    </div>
  );
}
