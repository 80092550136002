import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useTheme } from "@mui/material";
import pl from "date-fns/locale/pl";
import format from "date-fns/format";
import { Typography, Box } from "@mui/material";
import moment from "moment";
import "moment/locale/pl";

moment.locale("pl", {
  week: {
    dow: 8,
  },
});
moment.locale("pl");
const CustomTooltip = ({ active, payload, label, theme, dateType }) => {
  if (active && payload && payload.length) {
    //

    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dark,
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {dateType === "day" ? (
          <Typography variant="h3">
            Godzina:
            <span style={{ fontWeight: "bold" }}>
              {format(new Date(label), "HH:mm")}
            </span>
          </Typography>
        ) : dateType === "week" ? (
          <Typography variant="h3">
            Dzień tygodnia:
            <span style={{ fontWeight: "bold" }}>
              {moment(label).format("ddd - DD/MM")}
            </span>
          </Typography>
        ) : dateType === "month" ? (
          <Typography variant="h3">
            Dzień miesiąca:
            <span style={{ fontWeight: "bold" }}>
              {moment(label).format("DD/MM")}
            </span>
          </Typography>
        ) : (
          <Typography variant="h3">
            Miesiąc:
            <span style={{ fontWeight: "bold" }}>
              {moment(label).format("MM/yyyy")}
            </span>
          </Typography>
        )}
        <Typography variant="h3">
          Moc:{" "}
          <span style={{ fontWeight: "bold" }}>
            {payload[0].value / 1000} kWh
          </span>
        </Typography>
      </Box>
    );
  }

  return null;
};

const EnergyChart = ({ energyData, dateType }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={energyData}
        margin={{
          top: 50,
          right: 30,
          left: 30,
          bottom: 20,
        }}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.primary.success}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.primary.success}
              stopOpacity={0.2}
            />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          tick={{ fill: theme.palette.text.secondary }}
          minTickGap={50}
          dataKey="x"
          label={{
            fill: theme.palette.text.secondary,
            value: "Godzina",
            angle: 0,
            fontSize: "15",
            offset: "-5",
            fontWeight: "300",
            position: "insideBottomLeft",
          }}
          tickFormatter={(value) => {
            //
            if (dateType === "day") {
              return format(new Date(value), "HH:mm");
            }
            if (dateType === "week") {
              return moment(value).format("ddd");
            }
            if (dateType === "month") {
              return format(new Date(value), "dd/MM");
            }
            if (dateType === "year") {
              return format(new Date(value), "MM/yyyy");
            }
          }}
        />
        <YAxis
          tick={{ fill: theme.palette.text.secondary }}
          tickFormatter={(value) => {
            return value / 1000;
          }}
          label={{
            fill: theme.palette.text.secondary,
            value: "kW",
            angle: 0,
            fontSize: "15",
            offset: "15",
            fontWeight: "300",
            position: "top",
          }}
        />

        {/* <Legend /> */}
        <Tooltip
          content={<CustomTooltip theme={theme} dateType={dateType} />}
        />
        <Bar radius={[5, 5, 0, 0]} dataKey="y" fill="url(#colorPv)" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EnergyChart;
