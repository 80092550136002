import { api } from "./../funcs/AuthManager";

async function GetWeeklyAlerts() {
  return api
    .get(`/api/v1/weekly_alerts/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}
export default GetWeeklyAlerts;
