import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const UserMenu = ({ username, handleLogout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ textTransform: "none" }}
        endIcon={<ArrowDropDownIcon />}
        startIcon={
          <AccountCircleRoundedIcon
            sx={{
              color: theme.palette.text.secondary,
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
            }}
          />
        }
      >
        <Typography variant="h2">{username}</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
      </Menu>
    </div>
  );
};
export default UserMenu;
