import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputAdornment } from "@mui/material";
import PostGroup from "../api/PostGroup";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const AddGroupDialog = ({
  open,
  handleClose,
  handleForcedReload,
  handleSnackBarText,
  handleOpenSnackBar,
}) => {
  const theme = useTheme();
  const [groupName, setGroupName] = React.useState("");
  const [isNameEmpty, setIsNameEmpty] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState(0);
  const handleGroupChange = (e) => {
    setGroupName(e.target.value);
  };

  const handlePostGroup = async () => {
    setErrorCode(0);
    setIsNameEmpty(false);
    if (groupName === "") {
      setIsNameEmpty(true);
    } else {
      PostGroup(groupName)
        .then((res) => {
          if (res && res.data) {
            handleSnackBarText("Nowa grupa została dodana");
            handleOpenSnackBar();
            handleForcedReload();
            handleClose();
          }
        })
        .catch((err) => {
          setErrorCode(err.response.status);
        })
        .finally(() => {});
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Nowa grupa</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Wpisz nazwę nowej grupy i naciśnij przycisk "ZAPISZ". Nowa grupa
          pojawi się w tabeli, gdzie będziesz mógł przypisać do niej wybrane
          instalacje.
        </DialogContentText>
        <TextField
          id="outlined-basic"
          placeholder="Nazwa grupy"
          variant="outlined"
          value={groupName}
          onChange={handleGroupChange}
          InputProps={{
            sx: { borderRadius: "25px" },
            startAdornment: (
              <InputAdornment position="start">
                {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
              </InputAdornment>
            ),
          }}
        />
        {/* <Typography sx={{ color: theme.palette.primary.yellow }}> */}
        {errorCode === 0 ? (
          isNameEmpty ? (
            <Typography sx={{ color: theme.palette.primary.yellow }}>
              Nazwa grupy nie może być pusta
            </Typography>
          ) : (
            <></>
          )
        ) : errorCode === 400 ? (
          <Typography sx={{ color: theme.palette.primary.alert }}>
            Nie można utworzyć grupy o takiej nazwie
          </Typography>
        ) : errorCode >= 500 && errorCode < 600 ? (
          <Typography sx={{ color: theme.palette.primary.alert }}>
            Błąd po stronie serwera
          </Typography>
        ) : (
          <Typography sx={{ color: theme.palette.primary.alert }}>
            Niestandardowy błąd dodawania grupy
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handlePostGroup}>Zapisz</Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddGroupDialog;
