import React, { useEffect } from "react";
import MapGL, { Source, Layer, Marker, Popup } from "react-map-gl";
import { useState, useRef, useMemo } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Pin from "./Pin";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import SolarPower from "@mui/icons-material/SolarPower";
import SolarPanelImage from "../constants/SolarPanelImage.png";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import defaultMarker from "../constants/default_marker.png";

// import {clusterLayer, clusterCountLayer, unclusteredPointLayer} from './Layers';
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  // minHeight:'75vh',
  height: "100%",
  width: "100%",
  backgroundColor: theme.palette.primary.dark,
  // padding:'1.25rem',
  boxShadow: "0px 10px 20px -5px #000000",
  // borderRadius:'50px',
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "none",
}));
const StyledButton = styled(Button)(({ theme, active }) => ({
  width: "7.5rem",
  height: "1.125rem",
  background: theme.palette.primary.accentActive,
  boxShadow: "0px 10px 20px -5px #000000",
  borderRadius: "25px",
  textTransform: "none",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  "&:hover": {
    background: theme.palette.primary.accentActive,
  },
}));

const Map = ({ geoJson, markersData, handleSetPv }) => {
  console.log(markersData);
  const theme = useTheme();
  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "earthquakes",

    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "case",
        ["all", ["get", "is_working"]],
        theme.palette.primary.alert,
        theme.palette.primary.success,
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "earthquakes",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredPointLayer = {
    id: "unclustered-point",
    type: "symbol",
    source: "earthquakes",
    filter: ["!", ["has", "point_count"]],
    layout: {
      "icon-image": "border-image",
      "icon-size": 1.5,

      "icon-allow-overlap": true,
      "text-allow-overlap": true,
      // get the title name from the source's "title" property
      // 'text-field': ['get', 'status'],
      // 'text-font': [
      // 'Open Sans Semibold',
      // 'Arial Unicode MS Bold'
      // ],
      // 'text-offset': [0, 1.25],
      // 'text-anchor': 'top'
    },
    paint: {
      "icon-color": [
        "match",
        ["get", "status"],
        "WORKING",
        theme.palette.primary.success,
        "INVALID_MESSAGE",
        theme.palette.primary.alert,
        "NO_CONNECTION",
        theme.palette.primary.alert,
        "#ccc",
      ],
      "icon-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        1,
        0.3,
      ],
    },
  };
  //
  //

  const navigate = useNavigate();
  const [popupInfo, setPopupInfo] = useState(null);
  const [clusterId, setClusterId] = useState(null);
  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiZGdhZHZhbnQiLCJhIjoiY2t3NjltY2Y4MDZ1cjMzczJyOGJmZ3k3cyJ9.RgjAn06HQ8rMfOsuMnjVkw";
  const mapRef = useRef(null);

  const onClick = (event) => {
    const feature = event.features[0];
    //
    //
    if (event.features.length > 0) {
      markersData.map((value) => {
        //
        if (value.id === event.features[0].properties.object_id) {
          //
          handleSetPv(value);
        }
      });
    }

    if (feature.properties.cluster_id) {
      setClusterId(feature.properties.cluster_id);

      const mapboxSource = mapRef.current.getSource("earthquakes");

      mapboxSource.getClusterLeaves(
        feature.properties.cluster_id,
        feature.properties.point_count,
        0,
        function (err, aFeatures) {}
      );

      mapboxSource.getClusterExpansionZoom(
        feature.properties.cluster_id,
        (err, zoom) => {
          if (err) {
            return;
          }
          mapRef.current.easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500,
          });
        }
      );
    }
  };

  //

  //   const markers = useMemo(() => geoJson.features.map(i =>{
  //     if (i.geometry.coordinates[0] !== null){
  //       return (
  //         <Marker key={i.properties.object_id} longitude={i.geometry.coordinates[0]} latitude={i.geometry.coordinates[1]} >
  //         {markersData.map((value)=>{
  //           if (value.id === i.properties.object_id) {
  //             return(
  //             <Pin key={value.id} params={value} handleSetPv={handleSetPv}/>)
  //           }

  //               })
  //             }
  //         </Marker>
  //       )
  //     }
  //     else {
  //       return null
  //   }
  //       }
  //   ), []);

  // const mapRef = useRef(null);
  let hoveredStateId = null;
  return (
    <StyledPaper>
      <MapGL
        initialViewState={{
          longitude: geoJson.features.find((i) => i.geometry.coordinates[0])
            ? geoJson.features.find((i) => i.geometry.coordinates[0]).geometry
                .coordinates[0]
            : 18.3801702,
          latitude: geoJson.features.find((i) => i.geometry.coordinates[1])
            ? geoJson.features.find((i) => i.geometry.coordinates[1]).geometry
                .coordinates[1]
            : 53.6756389,
          zoom: 6,
        }}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/dgadvant/cl0wkk0q200of14qin7fmvknm"
        // mapStyle="mapbox://styles/dgadvant/ckwburp4h0fle14mo93z45ne8"
        // mapStyle="mapbox://styles/dgadvant/ckwbvc3hvge0614pcrbgw44ou"
        // mapStyle="mapbox://styles/dgadvant/ckwbv6v9n1ezm14mphrq8z5ps"
        // mapStyle="mapbox://styles/dgadvant/ckwbuzjp90fvu14ko5oj419bz"
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
        onClick={onClick}
        onLoad={() => {
          const map = mapRef.current.getMap();

          map.loadImage(defaultMarker, (error, image) => {
            if (error) throw error;
            if (!map.hasImage("border-image")) {
              map.addImage("border-image", image, {
                sdf: true,
              });
            }
          });
          map.on("click", "unclustered-point", (e) => {
            if (e.features.length > 0) {
              if (hoveredStateId !== null) {
                map.setFeatureState(
                  { source: "earthquakes", id: hoveredStateId },
                  { hover: false }
                );
              }
              hoveredStateId = e.features[0].id;
              map.setFeatureState(
                { source: "earthquakes", id: hoveredStateId },
                { hover: true }
              );
            }
          });

          // When the mouse leaves the state-fill layer, update the feature state of the
          // previously hovered feature.
          // map.on('mouseleave', 'unclustered-point', () => {
          // if (hoveredStateId !== null) {
          // map.setFeatureState(
          // { source: 'earthquakes', id: hoveredStateId },
          // { hover: false }
          // );
          // }
          // hoveredStateId = null;
          // });
        }}
        ref={mapRef}
      >
        <Source
          id="earthquakes"
          type="geojson"
          data={geoJson}
          cluster={true}
          clusterMaxZoom={8}
          clusterRadius={120}
          clusterProperties={{
            is_working: [
              "any",
              ["==", ["get", "status"], "NO_CONNECTION"],
              ["==", ["get", "status"], "INVALID_MESSAGE"],
            ],
          }}
          // clusterProperties= {['get', 'status']}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer
            geoJSONSourceOptions={{ generateId: true }}
            {...unclusteredPointLayer}
          />
        </Source>
      </MapGL>
    </StyledPaper>
  );
};

const MemoMap = React.memo(Map);
export default MemoMap;
