import * as React from "react";
import Cookies from "universal-cookie";
import { url } from "../constants/urls.js";
import axios from "axios";
const cookies = new Cookies();

const authContext = React.createContext();

export const useAuth = () => {
  const api = axios.create({
    baseURL: `${url}`,
  });
  const [authed, setAuthed] = React.useState(cookies.get("authed") || false);
  const username = cookies.get("username");
  return {
    username,
    authed,
    async login(username, password, remember) {
      return api
        .post(`/api/v1/api-token-auth-care/login/`, {
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.status === 200) {
            if (remember) {
              const today = new Date();
              const tomorrow = new Date(today);
              tomorrow.setDate(tomorrow.getDate() + 14);
              cookies.set("username", username, {
                path: "/",
                expires: tomorrow,
              });
              cookies.set("authed", true, { path: "/", expires: tomorrow });
              cookies.set("token", res.data.token, {
                path: "/",
                expires: tomorrow,
              });
            } else {
              cookies.set("username", username, { path: "/" });
              cookies.set("authed", true, { path: "/" });
              cookies.set("token", res.data.token, {
                path: "/",
              });
            }

            return res;
          }
        })
        .catch((err) => {
          cookies.set("authed", false, { path: "/" });

          return Promise.reject(err);
        });
    },
    logout() {
      return new Promise((res) => {
        cookies.set("username", "", { path: "/" });
        cookies.set("authed", false, { path: "/" });
        cookies.set("token", "", {
          path: "/",
        });
        res();
      });
    },
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const AuthConsumer = () => {
  return React.useContext(authContext);
};
export default AuthConsumer;
