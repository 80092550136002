import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(e, val, theme) {
//   return {
//     fontWeight:
//       val.indexOf(e) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const BasicSelect = ({ handleChange, val, array, type, isDisabled }) => {
  const theme = useTheme();
  //prop "type" indicates which parent component is passing the props and how they should be handled//
  return (
    <div>
      <FormControl
        sx={{
          width: 300,
          margin: "0.5rem",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            backgroundColor: theme.palette.primary.dark,
            boxShadow: "0px 5px 10px -5px #000000",
          },
        }}
      >
        <Select
          disabled={
            isDisabled !== undefined && isDisabled === true ? true : false
          }
          sx={{
            "& .MuiSelect-select": {
              padding: "0.5rem",
              paddingLeft: "1rem",
            },
          }}
          displayEmpty
          value={val}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em>
                  {type === "group"
                    ? "Wybierz grupę"
                    : type === "single"
                    ? "Wybierz instalację"
                    : type === "vendor"
                    ? "Wybierz producenta falownika"
                    : "Wybierz użytkownika"}
                </em>
              );
            } else {
              if (type === "group") {
                return `${selected.name} (${selected.number_of_pvs})`;
              }
              if (type === "single") {
                return selected.name;
              }
              if (type === "user") {
                return selected.email;
              }
              if (type === "vendor") {
                return selected.name;
              }
              if (type === "modem") {
                return selected.barcode;
              }
            }
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>
              {type === "group"
                ? "Wybierz grupę"
                : type === "single"
                ? "Wybierz instalację"
                : type === "vendor"
                ? "Wybierz producenta falownika"
                : type === "modem"
                ? "Wybierz modem"
                : "Wybierz użytkownika"}
            </em>
          </MenuItem>
          {array.map((e, i) => {
            return (
              <MenuItem key={i} value={type === "vendor" ? e.name : e}>
                {type === "group"
                  ? `${e.name} (${e.number_of_pvs})`
                  : type === "single"
                  ? e.name
                  : type === "vendor"
                  ? e.name
                  : type === "modem"
                  ? e.barcode
                  : e.email}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
export default BasicSelect;
