import { api } from "./../funcs/AuthManager";

async function GetPVGroup() {
  return api
    .get(`/api/v1/pv_group/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}
export default GetPVGroup;
