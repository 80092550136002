import { api } from "./../funcs/AuthManager";

async function PostTaskOrder(formData) {
  return api
    .post(`/api/v1/task_order/`, formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default PostTaskOrder;
