import { api } from "./../funcs/AuthManager";

async function PostRegisterCareUser(
  email,
  password_1,
  password_2,
  license_key
) {
  return api
    .post(`/api/v1/register_care_user/`, {
      email: email,
      password_1: password_1,
      password_2: password_2,
      license_key: license_key,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default PostRegisterCareUser;
