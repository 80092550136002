import React from "react";
import { Button } from "@mui/material";
import { colorConfig } from "../constants/colorConfig";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.text.primary,
  backgroundColor: "transparent",
  // borderRadius:'25px',
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  "&:hover": {
    background: "none",
    transform: "translate(0px, -2px)",
  },
}));

const NavButton = ({ text, active }) => {
  //
  return (
    <StyledButton>
      <Typography variant="h5">{text}</Typography>
    </StyledButton>
  );
};

export default NavButton;
