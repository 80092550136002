import { api } from "./../funcs/AuthManager";

async function PostGroup(groupName) {
  return api
    .post(`/api/v1/pv_group/`, {
      name: groupName,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default PostGroup;
