import { api } from "./../funcs/AuthManager";

async function PutPV(sn, formData) {
  return api
    .put(`/api/v1/pv_admin/${sn}/`, formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default PutPV;
