import { api } from "./../funcs/AuthManager";

async function GetPvAdminSn(sn) {
  return api
    .get(`/api/v1/pv_admin/${sn}/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default GetPvAdminSn;
