import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@emotion/react";

function CircularProgressWithLabel(props) {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size={props.sizeMain}
        sx={{
          position: "relative",
          zIndex: 2,
          [`& .${circularProgressClasses.svg}`]: {
            color: theme.palette.primary.success,
            strokeLinecap: "round",
            circle: {},
          },
        }}
        variant="determinate"
        {...props}
      />
      <CircularProgress
        size={props.sizeSecondary}
        value={100}
        variant="determinate"
        sx={{
          position: "absolute",
          zIndex: 1,
          [`& .${circularProgressClasses.svg}`]: {
            color: "rgba(0,0,0,0.3)",
            strokeLinecap: "round",
            circle: {
              strokeDashoffset: "0px",
            },
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1">{`${Math.round(props.value)}%`}</Typography>
        <Typography variant="h3">Wydajność</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function EfficiencyProgy({
  efficiency,
  sizeMain,
  sizeSecondary,
}) {
  const [progress, setProgress] = React.useState(0);
  const [loop, setLoop] = React.useState(null);
  const [paused, setPaused] = React.useState(false);
  const n = efficiency / 10;
  React.useEffect(() => {
    if (efficiency) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (efficiency <= prevProgress) {
            return efficiency;
          }
          if (prevProgress < efficiency) {
            return prevProgress + n;
          }
        });
      }, 100);

      return () => {
        clearInterval(timer);
      };
    } else {
      setProgress(0);
    }
  }, [efficiency]);

  //   React.useEffect(() => {
  //     const n = (efficiency/2)
  //     const m = parseInt(efficiency/n)
  //
  //
  //
  //
  //     const handleAnimation = () => {
  //         let test = [...Array(parseInt(n.toFixed(0)))].map((e,i)=>{
  //             setProgress((prevProgress) => (prevProgress >= efficiency ? efficiency : prevProgress + n))

  //
  //         })
  //         return test
  //     }
  //     handleAnimation()
  //   }, [efficiency]);
  return (
    <CircularProgressWithLabel
      value={progress}
      sizeMain={sizeMain}
      sizeSecondary={sizeSecondary}
    />
  );
}
