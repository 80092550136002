import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GetAvailablePvs from "../api/GetAvailablePvs";
import { useTheme } from "@mui/material/styles";
import Cookies from "universal-cookie";
import BasicSelect from "./BasicSelect";
import PatchPv from "../api/PatchPv";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";

const PlugPVToUser = ({ open, handleClose, userEmail, handleForceReload }) => {
  const theme = useTheme();
  const [availablePvs, setAvailablePvs] = React.useState("");
  const [pickedPv, setPickedPv] = React.useState(``);
  const [errorCode, setErrorCode] = React.useState(0);

  const handlePlugPv = async () => {
    setErrorCode(0);
    if (pickedPv) {
      PatchPv(pickedPv.sn, userEmail)
        .then((res) => {
          if (res) {
            //handleForceReload in case of success reloads the table and displays appropriate snackbar//
            handleForceReload();
          }
        })
        .catch((err) => {
          setErrorCode(err.response.status);
        })
        .finally(() => {});
    }
  };

  React.useEffect(() => {
    const handleOnLoad = async () => {
      let response = await GetAvailablePvs();
      if (response) {
        setAvailablePvs(response.data);
      }
    };
    handleOnLoad();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPickedPv(value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Przypisz wolną instalację do konta Klienta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Po wybraniu instalacji z listy rozwijanej i zapisaniu, Klient zyska do
          niej dostęp z poziomu swojego konta PV FLOW
          {errorCode === 0 ? (
            <></>
          ) : errorCode === 400 ? (
            <Typography sx={{ color: theme.palette.primary.alert }}>
              Złe dane wejściowe
            </Typography>
          ) : errorCode >= 500 && errorCode < 600 ? (
            <Typography sx={{ color: theme.palette.primary.alert }}>
              Błąd po stronie serwera
            </Typography>
          ) : (
            <Typography sx={{ color: theme.palette.primary.alert }}>
              Niestandardowy błąd
            </Typography>
          )}
        </DialogContentText>
        {availablePvs && (
          <BasicSelect
            type="single"
            val={pickedPv}
            array={availablePvs}
            handleChange={handleChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={pickedPv ? false : true}
          onClick={() => {
            handleClose();
            handlePlugPv();
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PlugPVToUser;
