import * as React from "react";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import PickersDay from "@mui/lab/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfISOWeek from "date-fns/startOfISOWeek";
import endOfISOWeek from "date-fns/endOfISOWeek";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { normal } from "../constants/colorConfig";

import { pl, enGB } from "date-fns/locale";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  color: theme.palette.primary.tertiary,
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiButtonBase-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-input": {
    color: "black",
    // color: theme.palette.primary.dark,
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontFamily: ["PT Sans, sans-serif"].join(","),

    "&:hover fieldset": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));
export default function CustomDay({
  currentDate,
  setCurrentDate,
  minDate,
  maxDate,
}) {
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!currentDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfISOWeek(currentDate);
    const end = endOfISOWeek(currentDate);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={pl}>
      <StyledDatePicker
        // displayStaticWrapperAs="desktop"
        label="Tydzień"
        value={currentDate}
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        mask={"__/__/____"}
        PaperProps={{
          sx: {
            "& .MuiButtonBase-root": {
              "&.MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#20356D",
                },
                "&:hover": {
                  backgroundColor: "#6E82BA",
                },
              },
            },
          },
        }}
        onChange={(newValue) => {
          setCurrentDate(startOfISOWeek(new Date(newValue)));
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => (
          <StyledTextField variant="standard" {...params} />
        )}
        inputFormat="dd/MM/yyyy"
      />
    </LocalizationProvider>
  );
}
