import { api } from "./../funcs/AuthManager";

async function PostPasswordReset(email) {
  return api
    .post(`/api/v1/password_reset/`, {
      email: email,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default PostPasswordReset;
