import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LightModeIcon from "@mui/icons-material/LightMode";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { styled, useTheme } from "@mui/material/styles";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import format from "date-fns/format";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GetEnergy from "../api/GetEnergy";
import GetPhaseDataOvervoltage from "../api/GetPhaseDataOvervoltage";
import GetPower from "../api/GetPower";
import GetStateData from "../api/GetStateData";
import GetUserList from "../api/GetUserList";
import GetVoltage from "../api/GetVoltage";
import AddTaskDialog from "../components/AddTaskDialog";
import CustomCircularProgress from "../components/CustomCircularProgress";
import CustomSnackBar from "../components/CustomSnackBar";
import DatePicker from "../components/DatePicker";
import EfficiencyProgress from "../components/EfficiencyProgress";
import EnergyChart from "../components/EnergyChart";
import MobileStatusBar from "../components/MobileStatusBar";
import MonthPicker from "../components/MonthPicker";
import PlugUserToPv from "../components/PlugUserToPv";
import PowerChart from "../components/PowerChart";
import RadioButtons from "../components/RadioButtons";
import SinglePVTable from "../components/SinglePVTable";
import ToggleButtons from "../components/ToggleButtons";
import VoltageChart from "../components/VoltageChart";
import WeekPicker from "../components/WeekPicker";
import YearPicker from "../components/YearPicker";
import SolarPanel from "../constants/SolarPanel.jpg";
import GetPvAdminSn from "./../api/GetPvAdminSn";
import GetPVStatus from "../api/GetPVStatus";
import GetLastEvent from "../api/GetLastEvent";
import AddPVDialog from "../components/AddPVDialog";
import GetEventData from "../api/GetEventData";
const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const theme = useTheme();

  return (
    <Pagination
      sx={{
        color: theme.palette.text.primary,
        "& .MuiButtonBase-root": {
          // backgroundColor:theme.palette.primary.accent,
          color: theme.palette.text.primary,
          borderColor: theme.palette.text.secondary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        "& .Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.dark,
          "&.Mui-focusVisible": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.dark,
          },
        },
      }}
      // color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
    // border:'none',
    // borderRadius:'15px',
    padding: "0",
    // background:theme.palette.primary.dark,
    boxShadow: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    // background:theme.palette.primary.dark,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-menuIcon": {
    color: theme.palette.text.primary,
    background: "transparent",
  },
  //   '& .MuiSvgIcon-root':{
  //       color:theme.palette.text.primary,
  //       background:'transparent',
  //   }
}));

const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height }) => ({
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: height,
    flexGrow: 1,
  })
);

const SinglePV = () => {
  const theme = useTheme();
  let { sn } = useParams();
  // let sn = "235346";

  const [pv, setPv] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [value, setValue] = useState("day");
  const [view, setView] = useState("power");
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [currentDate, setCurrentDate] = useState(``);
  const [userList, setUserList] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [forcedReload, setForcedReload] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [phaseDataOvervoltages, setPhaseDataOvervoltages] = useState("");
  const [stateData, setStateData] = useState("");
  const [powerData, setPowerData] = useState(``);
  const [energyData, setEnergyData] = useState(``);
  const [voltageData, setVoltageData] = useState(``);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [statusData, setStatusData] = useState(``);
  const [statusDataLoaded, setStatusDataLoaded] = useState(false);
  const [lastEvent, setLastEvent] = useState(``);
  const [openPVDrawer, setOpenPVDrawer] = useState(false);
  const [eventData, setEventData] = useState(``);

  useEffect(() => {
    if (pv) {
      setIsStatusLoading(true);
      setIsEventLoading(true);

      reloadPVStatus();
      setInterval(reloadPVStatus, 1000 * 60 * 5);

      GetLastEvent(pv.id)
        .then((res) => {
          if (res && res.data) {
            // setPv(res.data);
            setLastEvent(res.data);
            // setCurrentDate(new Date());
            // setStatusData(res.data);
          }
        })
        .catch((err) => {
          // setErrorCode(err.response.status);
        })
        .finally(() => {
          setIsEventLoading(false);
        });
    }
  }, [pv]);

  useEffect(() => {
    if (pv) {
      if (tabValue === 0 && phaseDataOvervoltages === "") {
        GetPhaseDataOvervoltage(pv.id)
          .then((res) => {
            if (res && res.data) {
              let temp = res.data.map((val, i) => {
                return { ...val, id: i + 1 };
              });
              setPhaseDataOvervoltages(temp);
            }
          })
          .catch((err) => {})
          .finally(() => {
            // setOvervoltagesLoading(false);
          });
      } else if (tabValue === 1 && stateData === "") {
        GetStateData(pv.id)
          .then((res) => {
            if (res && res.data) {
              let stateDataRows = [];
              res.data.forEach((element, index) => {
                const e = { id: index, ...element };
                stateDataRows.push(e);
              });
              setStateData(stateDataRows);
            }
          })
          .catch((err) => {})
          .finally(() => {
            // setOvervoltagesLoading(false);
          });
      } else if (tabValue === 2 && eventData === "") {
        GetEventData(pv.id)
          .then((res) => {
            if (res && res.data) {
              let eventDataRows = [];
              res.data.forEach((element, index) => {
                const e = { id: index, ...element };
                eventDataRows.push(e);
              });
              setEventData(eventDataRows);
            }
          })
          .catch((err) => {})
          .finally(() => {
            // setOvervoltagesLoading(false);
          });
      }
    }
  }, [pv, tabValue]);

  const reloadPVStatus = () => {
    if (pv) {
      GetPVStatus(pv.id)
        .then((res) => {
          if (res && res.data) {
            setStatusData(res.data);
            setStatusDataLoaded(true);
          }
        })
        .catch((err) => {
          // setErrorCode(err.response.status);
        })
        .finally(() => {
          setIsStatusLoading(false);
        });
    }
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const handleTabIndexChange = (index) => {
    setTabValue(index);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const handlePVDrawerOpen = () => {
    setOpenPVDrawer(true);
  };

  const handlePVDrawerClose = () => {
    setOpenPVDrawer(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    if (pv && currentDate) {
      if (view === "power") {
        GetPower(pv.id, currentDate)
          .then((res) => {
            if (res && res.data) {
              setPowerData(res.data);
            }
          })
          .catch((err) => {})
          .finally(() => {});
      }
      if (view === "energy") {
        GetEnergy(pv.id, currentDate, value)
          .then((res) => {
            if (res && res.data) {
              setEnergyData(res.data.chart_data);
            }
          })
          .catch((err) => {})
          .finally(() => {});
      }
      if (view === "voltage") {
        GetVoltage(
          pv.id,
          currentDate,
          value === "day" || value === "week" ? value : "day"
        )
          .then((res) => {
            if (res && res.data) {
              setVoltageData(res.data);
            }
          })
          .catch((err) => {})
          .finally(() => {});
      }
    }
  }, [currentDate, view, value]);

  useEffect(() => {
    if (sn) {
      setIsLoading(true);
      GetPvAdminSn(sn)
        .then((res) => {
          if (res && res.data) {
            setPv(res.data);
            setCurrentDate(new Date());
          }
        })
        .catch((err) => {
          // setErrorCode(err.response.status);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [forcedReload]);

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  const handleChange = (event, nextView) => {
    if (nextView) {
      setView(nextView);
      if (nextView === "power") {
        setValue("day");
      }
      if (nextView === "voltage") {
        setValue("day");
      }
    }
  };

  const handleGetUsers = async () => {
    GetUserList()
      .then((res) => {
        if (res && res.data) {
          setOpenDialog(true);
        }
      })
      .catch((err) => {
        // setErrorCode(err.response.status);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSnackBarText = (val) => {
    setSnackBarText(val);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleForcedReload = () => {
    setForcedReload((prev) => !prev);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  const mediumViewport = useMediaQuery("(max-width:1400px)");
  const smallViewport = useMediaQuery(theme.breakpoints.down("md"));
  const temp_status = true;
  const navigate = useNavigate();

  const phaseDataOvervoltagesColumns = [
    {
      field: "cap_dt",
      headerName: "Data i godzina",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.formattedValue);
        return moment(date).format("DD.MM | HH:mm");
      },
    },
    {
      field: "obis",
      headerName: "Faza",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        switch (params.formattedValue) {
          case "32.7.0":
            return "L1";
          case "52.7.0":
            return "L2";
          case "72.7.0":
            return "L3";
          default:
            return "-";
        }
      },
    },
    {
      field: "voltage",
      headerName: "Napięcie [V]",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const stateDataColumns = [
    {
      field: "cap_dt",
      headerName: "Data i godzina",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.formattedValue);
        return moment(date).format("DD.MM | HH:mm");
      },
    },
    {
      field: "operating_state",
      headerName: "Stan",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const eventsColumns = [
    {
      field: "cap_dt",
      headerName: "Data",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const date = new Date(params.formattedValue);
        return moment(date).format("DD.MM | HH:mm");
      },
      flex: 1,
    },
    {
      field: "event",
      headerName: "Zdarzenie",
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.event[0].event_dict;
      },
      align: "center",
      flex: 1,
    },
  ];

  if (pv && !isStatusLoading && !isEventLoading && !isLoading) {
    return (
      <StyledGridItem
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        spacing={2}
        justifyContent="flex-start"
      >
        <StyledGridItem
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "8vh",
            marginBottom: "1rem",
          }}
        >
          {statusData && smallViewport ? (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                background: theme.palette.primary.dark,
                flexDirection: "row",

                alignItems: "center",
                boxShadow: "0px 10px 20px -5px #000000",
              }}
            >
              <MobileStatusBar
                content={[
                  <Box
                    sx={{
                      display: "flex",
                      // height: "100%",
                      // width: "8rem",
                      borderRadius: "25px",
                      margin: "0.5rem",

                      justifyContent: "flex-start",
                      alignItems: "center",
                      background: theme.palette.primary.dark,
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                      Logger:
                    </Typography>
                    {console.log(statusData.status)}
                    {console.log(statusData.status == "NO_CONNECTION")}
                    {console.log(statusData.status === "WORKING")}
                    {statusData.status == "NO_CONNECTION" ? (
                      <Typography
                        variant="h3Alt"
                        sx={{
                          color: theme.palette.primary.alert,
                          fontWeight: "bold",
                        }}
                      >
                        Offline
                      </Typography>
                    ) : statusData.status === "WORKING" ? (
                      <Typography
                        variant="h3Alt"
                        sx={{ color: theme.palette.primary.success }}
                      >
                        Online
                      </Typography>
                    ) : (
                      statusData.status === "INVALID_MESSAGE" && (
                        <Typography
                          variant="h3Alt"
                          sx={{ color: theme.palette.primary.success }}
                        >
                          Online
                        </Typography>
                      )
                    )}
                  </Box>,
                  <Box
                    sx={{
                      display: "flex",
                      // height: "100%",
                      // width: "8rem",
                      borderRadius: "25px",
                      margin: "0.5rem",

                      justifyContent: "flex-start",
                      alignItems: "center",
                      background: theme.palette.primary.dark,
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                      Falownik:
                    </Typography>
                    {statusData.status === "WORKING" ? (
                      <Typography
                        variant="h3Alt"
                        sx={{ color: theme.palette.primary.success }}
                      >
                        Online
                      </Typography>
                    ) : statusData.status === "INVALID_MESSAGE" ? (
                      <Typography
                        variant="h3Alt"
                        sx={{
                          color: theme.palette.primary.alert,
                          fontWeight: "bold",
                        }}
                      >
                        Offline
                      </Typography>
                    ) : (
                      <Typography
                        variant="h3Alt"
                        sx={{
                          color: theme.palette.primary.alert,
                          fontWeight: "bold",
                        }}
                      >
                        Brak danych
                      </Typography>
                    )}
                  </Box>,
                  <Box
                    sx={{
                      display: "flex",
                      // height: "100%",
                      // width: "8rem",
                      borderRadius: "25px",
                      margin: "0.5rem",

                      justifyContent: "flex-start",
                      alignItems: "center",

                      background: theme.palette.primary.dark,
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                      Ostatnie zdarzenie:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          color: theme.palette.primary.alert,
                          paddingRight: "1rem",
                        }}
                      >
                        {pv.has_events
                          ? lastEvent && lastEvent.event.length > 0
                            ? lastEvent.event[0].event_dict
                            : "Brak danych"
                          : "Falownik nie posiada tej funkcjonalności"}
                      </Typography>
                      {pv.has_events &&
                        lastEvent &&
                        lastEvent.event.length > 0 && (
                          <Typography variant="h3Alt">
                            {format(
                              new Date(lastEvent.cap_dt),
                              "HH:mm  dd.MM.yyyy"
                            )}
                          </Typography>
                        )}
                    </Box>
                  </Box>,
                  <Box
                    sx={{
                      display: "flex",
                      // height: "100%",
                      // width: "8rem",
                      borderRadius: "25px",
                      margin: "0.5rem",
                      paddingLeft: "2rem",
                      paddingRight: "2rem",
                      justifyContent: "flex-start",
                      alignItems: "center",

                      background: theme.palette.primary.dark,
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                      Ostatnie połączenie:
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      {statusData && statusData.max_date && (
                        <Typography variant="h3Alt">
                          {format(
                            new Date(statusData.max_date),
                            "HH:mm  dd.MM.yyyy"
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Box>,
                  <Button
                    sx={{
                      borderRadius: "25px",
                      margin: "0.5rem",
                      fontWeight: "bold",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      border: "solid",
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      backgroundColor: theme.palette.primary.dark,
                      "& .MuiSvgIcon-root": {
                        transform: "translateX(-5px)",
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                        "& .MuiSvgIcon-root": {
                          transition: "transform 0.2s",
                          transform: "translateX(5px)",
                        },
                      },
                    }}
                    variant="contained"
                    endIcon={<EngineeringIcon />}
                    onClick={handleDrawerOpen}
                  >
                    Dodaj zgłoszenie
                  </Button>,
                  <Button
                    sx={{
                      borderRadius: "25px",
                      margin: "0.5rem",
                      fontWeight: "bold",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      border: "solid",
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      backgroundColor: theme.palette.primary.dark,
                      "& .MuiSvgIcon-root": {
                        transform: "translateX(-5px)",
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                        "& .MuiSvgIcon-root": {
                          transition: "transform 0.2s",
                          transform: "translateX(5px)",
                        },
                      },
                    }}
                    variant="contained"
                    endIcon={<EditRoundedIcon />}
                    onClick={handlePVDrawerOpen}
                  >
                    Edytuj instalację
                  </Button>,
                ]}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                background: theme.palette.primary.dark,
                flexDirection: "row",

                alignItems: "center",
                boxShadow: "0px 10px 20px -5px #000000",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  // width: "100%",
                  marginLeft: "1rem",
                  justifyContent: "flex-start",
                  background: "transparent",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    // height: "100%",
                    // width: "8rem",
                    borderRadius: "25px",
                    margin: "0.5rem",
                    padding: "0.8rem",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    background: theme.palette.primary.dark,
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                    Logger:
                  </Typography>
                  {console.log(statusData.status)}
                  {console.log(statusData.status == "NO_CONNECTION")}
                  {console.log(statusData.status === "WORKING")}
                  {statusData.status == "NO_CONNECTION" ? (
                    <Typography
                      variant="h3Alt"
                      sx={{
                        color: theme.palette.primary.alert,
                        fontWeight: "bold",
                      }}
                    >
                      Offline
                    </Typography>
                  ) : statusData.status === "WORKING" ? (
                    <Typography
                      variant="h3Alt"
                      sx={{ color: theme.palette.primary.success }}
                    >
                      Online
                    </Typography>
                  ) : (
                    statusData.status === "INVALID_MESSAGE" && (
                      <Typography
                        variant="h3Alt"
                        sx={{ color: theme.palette.primary.success }}
                      >
                        Online
                      </Typography>
                    )
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // height: "100%",
                    // width: "8rem",
                    borderRadius: "25px",
                    margin: "0.5rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    background: theme.palette.primary.dark,
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                    Falownik:
                  </Typography>
                  {statusData.status === "WORKING" ? (
                    <Typography
                      variant="h3Alt"
                      sx={{ color: theme.palette.primary.success }}
                    >
                      Online
                    </Typography>
                  ) : statusData.status === "INVALID_MESSAGE" ? (
                    <Typography
                      variant="h3Alt"
                      sx={{
                        color: theme.palette.primary.alert,
                        fontWeight: "bold",
                      }}
                    >
                      Offline
                    </Typography>
                  ) : (
                    <Typography
                      variant="h3Alt"
                      sx={{
                        color: theme.palette.primary.alert,
                        fontWeight: "bold",
                      }}
                    >
                      Brak danych
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // height: "100%",
                    // width: "8rem",
                    borderRadius: "25px",
                    margin: "0.5rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    justifyContent: "flex-start",
                    alignItems: "center",

                    background: theme.palette.primary.dark,
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                    Ostatnie zdarzenie:
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h3"
                      sx={{
                        color: theme.palette.primary.alert,
                        paddingRight: "1rem",
                      }}
                    >
                      {pv.has_events
                        ? lastEvent && lastEvent.event.length > 0
                          ? lastEvent.event[0].event_dict
                          : "Brak danych"
                        : "Falownik nie posiada tej funkcjonalności"}
                    </Typography>
                    {pv.has_events &&
                      lastEvent &&
                      lastEvent.event.length > 0 && (
                        <Typography variant="h3Alt">
                          {format(
                            new Date(lastEvent.cap_dt),
                            "HH:mm  dd.MM.yyyy"
                          )}
                        </Typography>
                      )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // height: "100%",
                    // width: "8rem",
                    borderRadius: "25px",
                    margin: "0.5rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    justifyContent: "flex-start",
                    alignItems: "center",

                    background: theme.palette.primary.dark,
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
                    Ostatnie połączenie:
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {statusData && statusData.max_date && (
                      <Typography variant="h3Alt">
                        {format(
                          new Date(statusData.max_date),
                          "HH:mm  dd.MM.yyyy"
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  // width: "100%",
                  justifyContent: "flex-end",
                  background: "transparent",
                  flexDirection: "row",
                }}
              >
                <Button
                  sx={{
                    borderRadius: "25px",
                    margin: "0.5rem",
                    fontWeight: "bold",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    border: "solid",
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.dark,
                    "& .MuiSvgIcon-root": {
                      transform: "translateX(-5px)",
                    },
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                      "& .MuiSvgIcon-root": {
                        transition: "transform 0.2s",
                        transform: "translateX(5px)",
                      },
                    },
                  }}
                  variant="contained"
                  endIcon={<EngineeringIcon />}
                  onClick={handleDrawerOpen}
                >
                  {!mediumViewport && "Dodaj zgłoszenie"}
                </Button>
                <Button
                  sx={{
                    borderRadius: "25px",
                    margin: "0.5rem",
                    fontWeight: "bold",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    border: "solid",
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.dark,
                    "& .MuiSvgIcon-root": {
                      transform: "translateX(-5px)",
                    },
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                      "& .MuiSvgIcon-root": {
                        transition: "transform 0.2s",
                        transform: "translateX(5px)",
                      },
                    },
                  }}
                  variant="contained"
                  endIcon={<EditRoundedIcon />}
                  onClick={handlePVDrawerOpen}
                >
                  {!mediumViewport && "Edytuj instalację"}
                </Button>
              </Box>
            </Box>
          )}
        </StyledGridItem>

        <StyledGridItem
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          alignitems="center"
          sx={
            {
              // background: theme.palette.primary.dark,
              // boxShadow: "0px 10px 20px -5px #000000",
            }
          }
          justifycontent="center"
          height={smallViewport ? "80vh" : mediumViewport ? "55vh" : "40vh"}
        >
          <StyledGridItem
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignitems="center"
            sx={
              !isStatusLoading
                ? {
                    background: theme.palette.primary.dark,
                    boxShadow: "0px 10px 20px -5px #000000",
                  }
                : {
                    display: "flex",
                  }
            }
            justifycontent="center"
            height="100%"
          >
            {isStatusLoading && (
              <Box>
                <CustomCircularProgress
                  size={80}
                  color={theme.palette.primary.dark}
                />
              </Box>
            )}
            {!isStatusLoading && (
              <React.Fragment>
                <StyledGridItem
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                  container
                  sx={{
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    // margin: "1rem",
                  }}
                >
                  <StyledGridItem
                    item
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "16rem",
                    }}
                  >
                    <Typography variant="h1">{pv && pv.description}</Typography>
                    <img src={SolarPanel} width="100%" height={80} />
                  </StyledGridItem>
                  <StyledGridItem
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <EfficiencyProgress
                      efficiency={
                        statusData &&
                        pv &&
                        (statusData.last_power / pv.generator_rated_power) * 100
                      }
                      sizeMain={120}
                      sizeSecondary={120}
                    />
                  </StyledGridItem>
                </StyledGridItem>

                <StyledGridItem
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    padding: "1rem",
                  }}
                >
                  <StyledGridItem
                    item
                    xl={3}
                    lg={6}
                    md={3}
                    sm={6}
                    xs={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <ElectricMeterIcon
                      sx={{
                        fontSize: "8vh",
                        color: theme.palette.primary.accent,
                        padding: "0.5rem",
                      }}
                    />
                    <Typography sx={{ padding: "0.25rem" }} variant="h2Alt">
                      {statusData && statusData.total_production ? (
                        statusData.total_production / 1000
                      ) : (
                        <>...</>
                      )}{" "}
                      kWh
                    </Typography>
                    <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                      Całkowita produkcja
                    </Typography>
                  </StyledGridItem>
                  <StyledGridItem
                    item
                    xl={3}
                    lg={6}
                    md={3}
                    sm={6}
                    xs={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <LightModeIcon
                      sx={{
                        fontSize: "8vh",
                        color: theme.palette.primary.brown,
                        padding: "0.5rem",
                      }}
                    />
                    <Typography sx={{ padding: "0.25rem" }} variant="h2Alt">
                      {statusData && statusData.today_production ? (
                        statusData.today_production / 1000
                      ) : (
                        <>...</>
                      )}{" "}
                      kWh
                    </Typography>
                    <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                      Dzisiejsza produkcja
                    </Typography>
                  </StyledGridItem>
                  <StyledGridItem
                    item
                    xl={3}
                    lg={6}
                    md={3}
                    sm={6}
                    xs={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <SolarPowerIcon
                      sx={{
                        fontSize: "8vh",
                        color: theme.palette.primary.yellow,
                        padding: "0.5rem",
                      }}
                    />
                    <Typography sx={{ padding: "0.25rem" }} variant="h2Alt">
                      {statusData && statusData.last_power ? (
                        statusData.last_power / 1000
                      ) : (
                        <>...</>
                      )}{" "}
                      kW
                    </Typography>
                    <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                      Obecna moc
                    </Typography>
                  </StyledGridItem>
                  <StyledGridItem
                    item
                    xl={3}
                    lg={6}
                    md={3}
                    sm={6}
                    xs={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <SsidChartIcon
                      sx={{
                        fontSize: "8vh",
                        color: theme.palette.primary.accentActive,
                        padding: "0.5rem",
                      }}
                    />
                    <Typography sx={{ padding: "0.25rem" }} variant="h2Alt">
                      {statusData ? (
                        <>
                          {statusData.last_phase_a} V
                          {statusData.last_phase_b &&
                            `/${statusData.last_phase_b} V /
                  ${statusData.last_phase_c}V`}
                        </>
                      ) : (
                        <>... V</>
                      )}
                    </Typography>
                    <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                      Obecne napięcie fazowe
                    </Typography>
                  </StyledGridItem>
                </StyledGridItem>
              </React.Fragment>
            )}
          </StyledGridItem>
        </StyledGridItem>
        <StyledGridItem
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          alignitems="center"
          sx={
            {
              // background: theme.palette.primary.dark,
              // boxShadow: "0px 10px 20px -5px #000000",
            }
          }
          justifycontent="center"
          height={smallViewport ? "80vh" : mediumViewport ? "55vh" : "40vh"}
        >
          <StyledGridItem
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignitems="center"
            sx={{
              padding: "1rem",
              background: theme.palette.primary.dark,
              boxShadow: "0px 10px 20px -5px #000000",
            }}
            justifycontent="center"
            height="100%"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <RadioButtons
                      value={value}
                      handleChangeRadio={handleChangeRadio}
                      view={view}
                    />
                  </Box>
                  <Box sx={{ marginRight: 3 }}>
                    {value === "day" && (
                      <DatePicker
                        minDate={minDate}
                        maxDate={maxDate}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                      />
                    )}
                    {value === "week" && (
                      <WeekPicker
                        minDate={minDate}
                        maxDate={maxDate}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                      />
                    )}
                    {value === "month" && (
                      <MonthPicker
                        minDate={minDate}
                        maxDate={maxDate}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                      />
                    )}
                    {value === "year" && (
                      <YearPicker
                        minDate={minDate}
                        maxDate={maxDate}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ToggleButtons view={view} handleChange={handleChange} />
                  </Box>
                  <Box
                    sx={{
                      height: "100%",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {view === "power" && powerData && (
                      <PowerChart powerData={powerData} />
                    )}
                    {view === "energy" && energyData && (
                      <EnergyChart dateType={value} energyData={energyData} />
                    )}
                    {view === "voltage" && voltageData && (
                      <VoltageChart
                        dateType={value}
                        voltageData={voltageData}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </StyledGridItem>
        </StyledGridItem>

        <StyledGridItem
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          alignitems="center"
          sx={
            {
              // background: theme.palette.primary.dark,
              // boxShadow: "0px 10px 20px -5px #000000",
            }
          }
          justifycontent="center"
          height={smallViewport ? "80vh" : mediumViewport ? "55vh" : "40vh"}
        >
          <StyledGridItem
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignitems="center"
            sx={{
              background: theme.palette.primary.dark,
              boxShadow: "0px 10px 20px -5px #000000",
            }}
            justifycontent="center"
            height="100%"
          >
            <StyledGridItem
              item
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem",
              }}
            >
              <StyledGridItem
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Instalacja
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.description}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Adres
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.address_street} {pv.address_number}
                  {pv.address_number_2 && `/${pv.address_number_2}`}{" "}
                  {pv.address_city} {pv.address_post_code}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Falownik i numer seryjny
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.pv_vendor} {pv.sn}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Moc falownika
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.inverter_rated_power / 1000} kW
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Moc generatora
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.generator_rated_power / 1000} kW
                </Typography>
              </StyledGridItem>
              <StyledGridItem
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Typ loggera
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.pv_vendor === "Solarman"
                    ? "Solarman logger"
                    : "Modem PV COMPLEX"}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Numer seryjny loggera
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.pv_modem}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Długość i szerokość geograficzna
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.longitude
                    ? `${pv.longitude}, ${pv.latitude}`
                    : "Nie podano"}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Azymut
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.azimuth ? pv.azimuth : "Nie podano"}
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3">
                  Nachylenie
                </Typography>
                <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                  {pv.elevation ? pv.elevation : "Nie podano"}
                </Typography>
              </StyledGridItem>
              <StyledGridItem
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ padding: "0.25rem" }} variant="h3">
                    Klient:
                  </Typography>
                  <Typography sx={{ padding: "0.25rem" }} variant="h3Alt">
                    {pv.user ? (
                      pv.user.email
                    ) : (
                      <Button
                        sx={{
                          borderRadius: "25px",
                          margin: "0.5rem",
                          fontWeight: "bold",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                          border: "solid",
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          backgroundColor: "transparent",
                          "& .MuiSvgIcon-root": {
                            transform: "translateX(-5px)",
                          },
                          "&:hover": {
                            backgroundColor: "transparent",
                            "& .MuiSvgIcon-root": {
                              transition: "transform 0.2s",
                              transform: "translateX(5px)",
                            },
                          },
                        }}
                        variant="contained"
                        endIcon={<PersonAddAltRoundedIcon />}
                        onClick={handleGetUsers}
                      >
                        Przypisz do Klienta
                      </Button>
                    )}
                  </Typography>
                </Box>
                <PlugUserToPv
                  sn={sn}
                  handleOpenSnackBar={handleOpenSnackBar}
                  handleSnackBarText={handleSnackBarText}
                  handleForcedReload={handleForcedReload}
                  userList={userList}
                  open={openDialog}
                  handleClose={handleCloseDialog}
                />
                <CustomSnackBar
                  openSnackBar={openSnackBar}
                  handleCloseSnackBar={handleCloseSnackBar}
                  text={snackBarText}
                />
              </StyledGridItem>
            </StyledGridItem>
          </StyledGridItem>
        </StyledGridItem>
        <StyledGridItem
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          alignitems="center"
          justifycontent="center"
          height={smallViewport ? "80vh" : mediumViewport ? "55vh" : "40vh"}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              // width: "100%",
              justifyContent: "flex-start",
              background: theme.palette.primary.dark,
              flexDirection: "column",
              boxShadow: "0px 10px 20px -5px #000000",
            }}
          >
            <StyledGridItem
              item
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <AppBar position="static"> */}
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="Przekroczenia napięć" />
                  <Tab label="Stany falownika" />
                  <Tab label="Zdarzenia na falowniku" />
                </Tabs>
                {/* </AppBar> */}
                {tabValue === 0 && (
                  <SinglePVTable
                    field="cap_dt"
                    data={phaseDataOvervoltages}
                    columns={phaseDataOvervoltagesColumns}
                  />
                )}
                {tabValue === 1 && (
                  <SinglePVTable
                    field="cap_dt"
                    data={stateData}
                    columns={stateDataColumns}
                  />
                )}
                {tabValue === 2 && (
                  <SinglePVTable
                    field="cap_dt"
                    data={eventData}
                    columns={eventsColumns}
                  />
                )}
              </Box>
            </StyledGridItem>
          </Box>
        </StyledGridItem>
        <AddPVDialog
          handleOpenSnackBar={handleOpenSnackBar}
          handleSnackBarText={handleSnackBarText}
          openDrawer={openPVDrawer}
          pv={pv}
          handleDrawerClose={handlePVDrawerClose}
        />
        <AddTaskDialog
          handleOpenSnackBar={handleOpenSnackBar}
          handleSnackBarText={handleSnackBarText}
          pv={pv}
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
        />
      </StyledGridItem>
    );
  } else {
    if (isStatusLoading || isEventLoading || isLoading) {
      return (
        <CustomCircularProgress
          size={120}
          position="absolute"
          top="50%"
          left="50%"
          color={theme.palette.primary.dark}
        />
      );
    } else {
      return null;
    }
  }
};

export default SinglePV;
