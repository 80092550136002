import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import useAxios from "axios-hooks";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import GetPVGroup from "../api/GetPVGroup";
import GetPVSList from "../api/GetPVSList";
import AddGroupDialog from "../components/AddGroupDialog";
import AddPVDialog from "../components/AddPVDialog";
import BasicSelect from "../components/BasicSelect";
import CustomCircularProgress from "../components/CustomCircularProgress";
import CustomSnackBar from "../components/CustomSnackBar";
import MainTable from "../components/MainTable";
import { url } from "../constants/urls.js";

const cookies = new Cookies();

const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent }) => ({
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: "90vh",
  })
);
const GroupDashboard = () => {
  const theme = useTheme();
  const [group, setGroup] = React.useState("");

  const [groups, setGroups] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [addPvOpen, setAddPvOpen] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [forcedReload, setForcedReload] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [snackBarText, setSnackBarText] = React.useState("");
  const [pagination, setPagination] = React.useState({});
  const token = cookies.get("token");
  let { groupId } = useParams();
  const navigate = useNavigate();

  const [{ data, loading }, refetch, cancelRequest] = useAxios({
    url: `${url}/api/v1/pvs_list/${group.id}/`,
    params: { ...pagination },
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  React.useEffect(() => {
    const handleOnLoad = () => {
      setIsLoading(true);
      GetPVGroup()
        .then((res) => {
          if (res && res.data) {
            setGroups(res.data);
            if (groupId) {
              const selectedGroup = res.data.find((g) => g.id == groupId);
              if (selectedGroup) {
                setGroup(selectedGroup);
              } else {
                navigate("/groups");
              }
            } else if (
              res.data.length > 0 === true &&
              res.data[0] &&
              res.data[0].id
            ) {
              navigate(`/groups/${res.data[0].id}`);
              setGroup(res.data[0]);
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    };
    handleOnLoad();
  }, [forcedReload]);

  React.useEffect(() => {
    const defaultPage = 1;
    if (group) {
      const handleGroupChange = () => {
        GetPVSList(group.id, defaultPage)
          .then((res) => {})
          .catch((err) => {})
          .finally(() => {});
      };
      handleGroupChange();
    }
  }, [group]);

  React.useEffect(() => {
    if (!groupId) {
      setGroup("");
      handleForcedReload();
    }
  }, [groupId]);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleSnackBarText = (val) => {
    setSnackBarText(val);
  };

  const handleForcedReload = () => {
    setForcedReload((prev) => !prev);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleAddPvClickOpen = () => {
    setAddPvOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddPvClickClose = (event, reason) => {
    setAddPvOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setPagination({ page: page });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setGroup(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (value && value.id) {
      navigate(`/groups/${value.id}`);
    }
  };

  return (
    <StyledGridItem item container lg={12} md={12} sm={12} xs={12}>
      <StyledGridItem
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        sx={{ padding: "1rem" }}
      >
        {isLoading ? (
          <CustomCircularProgress
            size={120}
            position="absolute"
            top="50%"
            left="50%"
            color={theme.palette.primary.dark}
          />
        ) : (
          groups && (
            <MainTable
              handleClickOpen={handleClickOpen}
              handleAddPvClickOpen={handleAddPvClickOpen}
              group={group}
              groupSelector={
                <BasicSelect
                  type="group"
                  val={group}
                  array={groups}
                  handleChange={handleChange}
                />
              }
              data={data}
              loading={loading}
              refetch={refetch}
              handlePageChange={handlePageChange}
            />
          )
        )}

        <AddPVDialog
          handleOpenSnackBar={handleOpenSnackBar}
          handleSnackBarText={handleSnackBarText}
          openDrawer={addPvOpen}
          handleDrawerClose={handleAddPvClickClose}
          addingMode
          currentGroup={group}
        />

        <AddGroupDialog
          handleForcedReload={handleForcedReload}
          handleOpenSnackBar={handleOpenSnackBar}
          handleSnackBarText={handleSnackBarText}
          open={open}
          handleClose={handleClose}
        />
        <CustomSnackBar
          openSnackBar={openSnackBar}
          handleCloseSnackBar={handleCloseSnackBar}
          text={snackBarText}
        />
      </StyledGridItem>
      {/* <StyledGridItem item lg={6} md={12} sm={12} xs={12} alignitems='center' justifycontent='center' sx={{padding:'1rem'}}>
        </StyledGridItem> */}
    </StyledGridItem>
  );
};

export default GroupDashboard;
