import { api } from "./../funcs/AuthManager";

async function GetAvailablePvs() {
  return api
    .get(`/api/v1/available_pvs/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default GetAvailablePvs;
