import React from "react";
import { Paper, Box, Grid, IconButton, Button } from "@mui/material";
import { colorConfig } from "../constants/colorConfig";
import { styled, useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import NavButton from "../components/NavButton";
import logo from "../constants/logo.svg";
// import NavButton from './NavButton';
import { useAuth } from "../funcs/useAuth";
import { NavLink, useMatch, useResolvedPath, Outlet } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MobileMenu from "./MobileMenu";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import UserMenu from "./UserMenu";

// import Menu from '../components/Menu'

const StyledGridContainer = styled(Grid)(({ theme, minheight }) => ({
  minHeight: minheight,
  backgroundColor: theme.palette.primary.main,
  width: "100%",
  minHeight: "100vh",
  padding: 0,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent }) => ({
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: "5rem",
  })
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "5rem",
  borderRadius: "0",
  backgroundColor: theme.palette.primary.dark,
  boxShadow: "none",
  width: "100%",
  // position:'absolute',
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <NavLink
      style={({ isActive }) => {
        return {
          textDecoration: "none",
          borderBottom: isActive ? `3px solid black` : "none",
        };
      }}
      to={to}
      {...props}
    >
      {children}
    </NavLink>
  );
}

const Navbar = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { username } = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    logout().then((res) => {
      navigate("/login");
    });
  };

  const largeViewport = useMediaQuery("(min-width:1440px)");

  return (
    <StyledGridContainer container>
      <StyledGridItem item container lg={12} md={12} sm={12} xs={12}>
        <StyledPaper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "20%",
              justifyContent: "space-between",
              marginLeft: "2rem",
            }}
          >
            <Typography variant="h1" sx={{ padding: 0 }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: "3rem", margin: "0.5rem" }}
              />
            </Typography>
          </Box>
          {!largeViewport ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                maxWidth: "50%",
                justifyContent: "flex-end",
                marginRight: "2rem",
              }}
            >
              <Typography variant="h2">{username}</Typography>
              <IconButton onClick={handleClick}>
                <MenuRoundedIcon />
              </IconButton>
              <MobileMenu
                content={[
                  <CustomLink to="/">
                    <NavButton text="Ekran główny" />
                  </CustomLink>,
                  <CustomLink to="/groups">
                    <NavButton text="Grupy" />
                  </CustomLink>,
                  <CustomLink to="/users">
                    <NavButton text="Twoi Klienci" />
                  </CustomLink>,
                  <CustomLink to="/work-orders">
                    <NavButton text="Serwis" />
                  </CustomLink>,
                ]}
                handleLogout={handleLogout}
                handleClose={handleClose}
                open={open}
                anchorEl={anchorEl}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                maxWidth: "40%",
                justifyContent: "flex-end",
                marginRight: "2rem",
              }}
            >
              <CustomLink to="/">
                <NavButton text="Ekran główny" />
              </CustomLink>
              <CustomLink to="/groups">
                <NavButton text="Grupy" />
              </CustomLink>
              <CustomLink to="/users">
                <NavButton text="Twoi Klienci" />
              </CustomLink>
              <CustomLink to="/work-orders">
                <NavButton text="Serwis" />
              </CustomLink>
              <UserMenu username={username} handleLogout={handleLogout} />
            </Box>
          )}
        </StyledPaper>
      </StyledGridItem>
      <Outlet />
    </StyledGridContainer>
  );
};

export default Navbar;
