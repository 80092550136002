import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
// import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from "./pages/Dashboard";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
// import Facility from './screens/Facility';
import LoginPage from "./pages/LoginPage";
import UsersManagement from "./pages/UsersManagement";
import Navbar from "./components/Navbar";
import { AuthProvider } from "./funcs/useAuth";
import { palette } from "@mui/system";
import { RequireAuth } from "./funcs/RequireAuth";
import GroupDashboard from "./pages/GroupDashboard";
import { InterceptProvider } from "./funcs/AuthManager";
import { plPL } from "@mui/x-data-grid";

import SinglePV from "./pages/SinglePV";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import WorkOrders from "./pages/WorkOrders";
const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1401,
      },
    },
  },
  {
    palette: {
      primary: {
        main: "#20356D",
        dark: "rgba(255,255,255,0.9)",
        accentActive: "#234CBA",
        accent: "#6E82BA",
        alert: "#FF4141",
        success: "#00A70B",
        tertiary: "rgba(25,124,42, 1)",
        yellow: "#BABA00",
        brown: "#BA8049",
      },
      text: {
        primary: "rgba(0,0,0,0.9)",
        secondary: "rgba(0,0,0,0.5)",
        tertiary: "rgba(255,255,255,0.9)",
      },
    },
  },
  {
    typography: {
      h2Alt: {
        fontSize: "2vh",
        lineHeight: "1.4rem",
        fontWeight: "700",
        color: "rgba(0,0,0,0.9)",
        fontFamily: ["PT Sans, sans-serif"].join(","),
      },
      h3Alt: {
        fontSize: "1.8vh",
        lineHeight: "1.2rem",
        fontWeight: "500",
        color: "rgba(0,0,0,0.9)",
        fontFamily: ["PT Sans, sans-serif"].join(","),
      },
    },
  },
  plPL
);
theme.typography.h1 = {
  fontSize: "1.5em",
  lineHeight: "1.5rem",
  fontWeight: "700",
  color: theme.palette.text.primary,
  fontFamily: ["PT Sans, sans-serif"].join(","),
  paddingLeft: "3rem",
  paddingRight: "3rem",
};
theme.typography.h2 = {
  fontSize: "1rem",
  lineHeight: "1.2rem",
  fontWeight: "500",
  color: theme.palette.text.primary,
  fontFamily: ["PT Sans, sans-serif"].join(","),
};
theme.typography.h3 = {
  fontSize: "1rem",
  lineHeight: "1.2rem",
  fontWeight: "500",
  color: theme.palette.text.secondary,
  fontFamily: ["PT Sans, sans-serif"].join(","),
};
theme.typography.h4 = {
  fontSize: "0.75rem",
  lineHeight: "0.875rem",
  fontWeight: "500",
  color: theme.palette.text.primary,
  fontFamily: ["PT Sans, sans-serif"].join(","),
};

//H5 FOR BUTTONS
theme.typography.h5 = {
  fontSize: "1rem",
  lineHeight: "1.2rem",
  fontWeight: "700",
  // color:'rgba(0,0,0,1)',
  fontFamily: ["PT Sans, sans-serif"].join(","),
};

function App() {
  return (
    <InterceptProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
              <Route element={<Navbar />}>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      {/* <Dashboard token={token}/> */}
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="groups"
                  element={
                    <RequireAuth>
                      <GroupDashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="groups/:groupId"
                  element={
                    <RequireAuth>
                      <GroupDashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="pv/:sn"
                  element={
                    <RequireAuth>
                      <SinglePV />
                    </RequireAuth>
                  }
                />
                <Route
                  path="users"
                  element={
                    <RequireAuth>
                      <UsersManagement />
                    </RequireAuth>
                  }
                />
                <Route
                  path="work-orders"
                  element={
                    <RequireAuth>
                      <WorkOrders />
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </InterceptProvider>
  );
}

export default App;
