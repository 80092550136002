import * as React from "react";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { styled, useTheme } from "@mui/material/styles";
import { pl, enGB } from "date-fns/locale";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiButtonBase-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-input": {
    color: "black",
    // color: theme.palette.primary.dark,
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontFamily: ["PT Sans, sans-serif"].join(","),

    "&:hover fieldset": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));

export default function BasicDatePicker({
  setCurrentDate,
  currentDate,
  minDate,
  maxDate,
}) {
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={pl}>
      <StyledDatePicker
        showTodayButton
        allowSameDateSelection={true}
        label={"Dzień"}
        // minDate={new Date(minDate)}
        // maxDate={new Date(maxDate)}
        value={currentDate}
        PaperProps={{
          sx: {
            "& .MuiButtonBase-root": {
              "&.MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#20356D",
                },
                "&:hover": {
                  backgroundColor: "#6E82BA",
                },
              },
            },
          },
        }}
        mask={"__/__/____"}
        onChange={(newValue) => {
          setCurrentDate(newValue);
        }}
        renderInput={(params) => (
          <StyledTextField variant="standard" {...params} />
        )}
        inputFormat="dd/MM/yyyy"
      />
    </LocalizationProvider>
  );
}
