import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GetAvailablePvs from "../api/GetAvailablePvs";
import { useTheme } from "@mui/material/styles";
import BasicSelect from "./BasicSelect";
import PatchPv from "../api/PatchPv";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";

const PlugUserToPv = ({
  open,
  handleClose,
  userList,
  sn,
  handleSnackBarText,
  handleOpenSnackBar,
  handleForcedReload,
}) => {
  const theme = useTheme();
  const [pickedUser, setPickedUser] = React.useState(``);
  const [errorCode, setErrorCode] = React.useState(0);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPickedUser(value);
  };
  const handlePlugPV = async () => {
    PatchPv(sn, pickedUser.email)
      .then((res) => {
        if (res && res.data) {
          handleSnackBarText("Instalacja przypisana pomyślnie");
          handleOpenSnackBar();
          handleForcedReload();
        }
      })
      .catch((err) => {
        setErrorCode(err.response.status);
      })
      .finally(() => {});
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Przypisz wolną instalację do konta Klienta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Po wybraniu instalacji z listy rozwijanej i zapisaniu, Klient zyska do
          niej dostęp z poziomu swojego konta PV FLOW
          {errorCode === 0 ? (
            <></>
          ) : errorCode === 400 ? (
            <Typography sx={{ color: theme.palette.primary.alert }}>
              Złe dane wejściowe
            </Typography>
          ) : errorCode >= 500 && errorCode < 600 ? (
            <Typography sx={{ color: theme.palette.primary.alert }}>
              Błąd po stronie serwera
            </Typography>
          ) : (
            <Typography sx={{ color: theme.palette.primary.alert }}>
              Niestandardowy błąd
            </Typography>
          )}
        </DialogContentText>
        {userList && (
          <BasicSelect
            type="user"
            val={pickedUser}
            array={userList}
            handleChange={handleChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={pickedUser ? false : true}
          onClick={() => {
            handlePlugPV();
            handleClose();
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PlugUserToPv;
