import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Paper, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskStatusHandler from "./TaskStatusHandler";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import FmdBadRoundedIcon from "@mui/icons-material/FmdBadRounded";
import format from "date-fns/format";
import CustomCircularProgress from "../components/CustomCircularProgress";
import PatchTaskOrder from "../api/PatchTaskOrder";
const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: theme.palette.primary.dark,
  borderRadius: 0,
  boxShadow: "0px 10px 20px -5px #000000",
}));

const TaskCard = ({
  pickedRow,
  handleCloseCard,
  handleSelectionModel,
  handleOpenSnackBar,
  handleSnackBarText,
  handleReload,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [status, setStatus] = useState(parseInt(pickedRow.status));

  const handleStatusChange = (e) => {
    setStatus(parseInt(e.target.value));
  };
  const handleHidden = () => {
    setHidden(false);
  };
  useEffect(() => {
    setStatus(parseInt(pickedRow.status));
  }, [pickedRow]);
  const handlePatch = async () => {
    PatchTaskOrder(pickedRow.id, status)
      .then((res) => {
        handleOpenSnackBar();
        handleSnackBarText("Zmiany zapisane pomyślnie");
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
        handleReload();
        setHidden(true);
      });
  };
  return (
    <StyledPaper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" sx={{ padding: "1rem" }}>
            Numer zgłoszenia:{" "}
            <span style={{ fontWeight: "bold" }}>{pickedRow.id}</span>
          </Typography>
          <Typography variant="h2" sx={{ padding: "1rem" }}>
            Data utworzenia:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pickedRow.creation_date
                ? format(new Date(pickedRow.creation_date), "HH:mm, dd.MM.yyyy")
                : "---"}
            </span>
          </Typography>
          <Typography variant="h2" sx={{ padding: "1rem" }}>
            Data aktualizacji:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pickedRow.update_date
                ? format(new Date(pickedRow.update_date), "HH:mm, dd.MM.yyyy")
                : "---"}
            </span>
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography variant="h2" sx={{ padding: "1rem" }}>
              Status:
            </Typography>
            {status === 1 ? (
              <CheckCircleRoundedIcon
                sx={{ color: theme.palette.primary.success }}
              />
            ) : (
              <FmdBadRoundedIcon sx={{ color: theme.palette.primary.yellow }} />
            )}
            {hidden ? (
              <Typography variant="h2" sx={{ padding: "1rem" }}>
                {status === 1 ? (
                  <span style={{ fontWeight: "bold" }}>
                    Zgłoszenie zamknięte
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold" }}>Zgłoszenie otwarte</span>
                )}
              </Typography>
            ) : (
              <TaskStatusHandler
                handleChange={handleStatusChange}
                value={status}
              />
            )}

            <Button
              sx={{
                borderRadius: "25px",
                margin: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                "& .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  "& .MuiSvgIcon-root": {
                    transition: "transform 0.2s",
                    transform: "translateX(5px)",
                  },
                },
              }}
              disabled={isLoading}
              variant="contained"
              // endIcon={<SendIcon />}
              onClick={() => {
                if (hidden) {
                  handleHidden();
                } else {
                  handlePatch();
                }
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.palette.text.tertiary }}
              >
                {hidden ? "Zmień" : "Zapisz"}
              </Typography>

              {isLoading && (
                <CustomCircularProgress
                  size={24}
                  position="absolute"
                  top="50%"
                  left="50%"
                  marginTop="-12px"
                  marginLeft="-12px"
                />
              )}
            </Button>
          </Box>
        </Box>

        <IconButton
          onClick={() => {
            handleSelectionModel("");
            handleCloseCard();
          }}
          sx={{ padding: "1rem" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Typography variant="h1" sx={{ padding: "1rem" }}>
          Dane instalacji
        </Typography>
        <Typography variant="h2" sx={{ padding: "1rem" }}>
          Nazwa:{" "}
          <span style={{ fontWeight: "bold" }}>{pickedRow.description}</span>
        </Typography>
        <Typography variant="h2" sx={{ padding: "1rem" }}>
          Numer seryjny falownika:{" "}
          <span style={{ fontWeight: "bold" }}>{pickedRow.inverter_sn}</span>
        </Typography>
        <Typography variant="h2" sx={{ padding: "1rem" }}>
          Adres: <span style={{ fontWeight: "bold" }}>{pickedRow.adress}</span>
        </Typography>
        <Typography variant="h1" sx={{ padding: "1rem" }}>
          Powód zgłoszenia
        </Typography>
        <Typography variant="h2" sx={{ padding: "1rem" }}>
          {pickedRow.reason}
        </Typography>
        <Typography variant="h1" sx={{ padding: "1rem" }}>
          Uwagi
        </Typography>
        <Typography variant="h2" sx={{ padding: "1rem" }}>
          {pickedRow.order}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

export default TaskCard;
