import React from "react";
import { Paper, Dialog, DialogTitle } from "@mui/material";
import PinInput from "react-pin-input";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Button from "@mui/material/Button";
import PostUserFlow from "./../api/PostUserFlow";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const RegisterFlowUser = ({
  openRegister,
  handleCloseRegister,
  handleOpenSnackBar,
  handleSnackBarText,
  handleForceTableReload,
}) => {
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [reqFailCode, setReqFailCode] = React.useState(0);
  const [isEmailEmpty, setIsEmailEmpty] = React.useState(false);
  const [isPhoneEmpty, setIsPhoneEmpty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRegisterNewUser = () => {
    setReqFailCode(0);
    setIsEmailEmpty(false);
    setIsPhoneEmpty(false);
    if (email === "") {
      setIsEmailEmpty(true);
    } else if (phone === "") {
      setIsPhoneEmpty(true);
    } else {
      setIsLoading(true);
      PostUserFlow(email, phone)
        .then((res) => {
          handleCloseRegister();
          handleSnackBarText("Użytkownik dodany pomyślnie");
          handleOpenSnackBar();
          handleForceTableReload();
        })
        .catch((err) => {
          setReqFailCode(err.response.status);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Dialog
      open={openRegister}
      onClose={() => {
        setEmail("");
        setPhone("");
        handleCloseRegister();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Zarejestruj nowego użytkownika
      </DialogTitle>

      <Paper sx={{ padding: "2rem" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Adres email:</span>
          <TextField
            id="outlined-basic"
            placeholder="Adres email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              sx: { borderRadius: "25px" },
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <br />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Numer telefonu:</span>
          <PinInput
            length={9}
            initialValue=""
            onChange={(value) => setPhone(value)}
            type="numeric"
            inputMode="number"
            inputStyle={{
              borderColor: theme.palette.primary.accent,
              backgroundColor: "transparent",
              width: "1rem",
              height: "2rem",
              color: "black",
              fontSize: "1rem",
              borderRadius: "10px",
              fontFamily: ["PT Sans, sans-serif"].join(","),
            }}
            inputFocusStyle={{
              borderColor: theme.palette.primary.accentActive,
              backgroundColor: "transparent",
            }}
            onComplete={(value, index) => {}}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flex: 1,
          }}
        >
          {/* <Button
            variant="contained"
            endIcon={<PersonAdd />}
            style={{ width: "100%" }}
            onClick={handleRegisterNewUser}
          >
            Zarejestruj
          </Button> */}
          <Box sx={{ m: 1, position: "relative", width: "100%" }}>
            <Button
              disabled={isLoading}
              sx={{
                borderRadius: "25px",
                margin: "0.5rem",
                position: "relative",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                "& .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              variant="contained"
              onClick={handleRegisterNewUser}
            >
              Zarejestruj
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          {reqFailCode === 0 ? (
            isEmailEmpty ? (
              <span style={{ color: theme.palette.primary.warning }}>
                Nie podano adresu email
              </span>
            ) : isPhoneEmpty ? (
              <span style={{ color: theme.palette.primary.warning }}>
                Nie podano numeru telefonu
              </span>
            ) : (
              <></>
            )
          ) : reqFailCode === 400 ? (
            <span style={{ color: theme.palette.primary.alert }}>
              Użytkonik o takich danych już istnieje
            </span>
          ) : reqFailCode >= 500 && reqFailCode < 600 ? (
            <span style={{ color: theme.palette.primary.alert }}>
              Błąd po stronie serwera
            </span>
          ) : (
            <span style={{ color: theme.palette.primary.alert }}>
              Niestandardowy błąd logowania
            </span>
          )}
        </div>
      </Paper>
    </Dialog>
  );
};

export default RegisterFlowUser;
