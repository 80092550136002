import { api } from "./../funcs/AuthManager";

async function DeleteUserFlow(email) {
  return api
    .delete(`/api/v1/delete_flow_user/${email}/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default DeleteUserFlow;
