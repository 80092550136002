import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { pl } from "date-fns/locale";
import * as React from "react";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  color: theme.palette.primary.tertiary,
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiButtonBase-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-input": {
    color: "black",
    // color: theme.palette.primary.dark,
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontFamily: ["PT Sans, sans-serif"].join(","),

    "&:hover fieldset": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));

export default function MonthDatePicker({
  setCurrentDate,
  currentDate,
  minDate,
  maxDate,
}) {
  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={pl}>
      <StyledDatePicker
        views={["year", "month"]}
        label="Rok i miesiąc"
        minDate={minDate}
        maxDate={maxDate}
        // clearable={true}
        allowSameDateSelection={true}
        showTodayButton={true}
        PaperProps={{
          sx: {
            "& .MuiButtonBase-root": {
              "&.MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#20356D",
                },
                "&:hover": {
                  backgroundColor: "#6E82BA",
                },
              },
            },
          },
        }}
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        value={currentDate}
        onChange={(newValue) => {
          setCurrentDate(newValue);
        }}
        renderInput={(params) => (
          <StyledTextField {...params} variant="standard" />
        )}
      />
    </LocalizationProvider>
  );
}
