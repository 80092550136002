import * as React from "react";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTheme } from "@mui/styles";
export default function VerticalToggleButtons({ handleChange, view }) {
  const theme = useTheme();
  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={view}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton
        sx={{
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        }}
        value="power"
        aria-label="power"
      >
        Moc
        <ShowChartIcon />
      </ToggleButton>
      <ToggleButton
        sx={{
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        }}
        value="energy"
        aria-label="energy"
      >
        Energia
        <BarChartIcon />
      </ToggleButton>
      <ToggleButton
        sx={{
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        }}
        value="voltage"
        aria-label="voltage"
      >
        Napięcie
        <SsidChartIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
