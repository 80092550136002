import { api } from "./../funcs/AuthManager";

async function GetFlowPvsList(email) {
  return api
    .get(`/api/v1/flow_pvs_list/${email}/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}
export default GetFlowPvsList;
