import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";

const TaskStatusHandler = ({ value, handleChange }) => {
  const theme = useTheme();

  return (
    <FormControl sx={{ padding: "1rem" }}>
      {/* <FormLabel id="demo-controlled-radio-buttons-group">
        <Typography variant="h2">Status</Typography>
      </FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value={0}
          control={<Radio />}
          label={<Typography variant="h2">Otwarte</Typography>}
        />
        <FormControlLabel
          value={1}
          control={<Radio />}
          label={<Typography variant="h2">Zamknięte</Typography>}
        />
      </RadioGroup>
    </FormControl>
  );
};
export default TaskStatusHandler;
