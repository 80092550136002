import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import EfficiencyProgress from "./EfficiencyProgress";
import EfficiencyProgressMobile from "./EfficiencyProgressMobile";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import CellWifiIcon from "@mui/icons-material/CellWifi";
import BlockIcon from "@mui/icons-material/Block";
import GridOnIcon from "@mui/icons-material/GridOn";
import GridOffIcon from "@mui/icons-material/GridOff";
import BoltIcon from "@mui/icons-material/Bolt";
import SolarPanel from "../constants/SolarPanel.jpg";
const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height }) => ({
    display: "flex",
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: height,
    flexGrow: 1,
  })
);
const StyledBox = styled(Box)(({ theme, justifycontent, alignitems }) => ({
  display: "flex",
  justifyContent: justifycontent,
  alignItems: alignitems,
  height: "100%",
  // width:'100%',
  flexDirection: "column",
}));
const Preview = ({ pv, efficiency }) => {
  const theme = useTheme();
  const smallViewport = useMediaQuery(theme.breakpoints.down("md"));
  const xsViewport = useMediaQuery("(max-width:540px)");
  console.log(pv);
  console.log(efficiency);

  return (
    <StyledBox
      sx={{
        background: theme.palette.primary.dark,
        width: "100%",
        boxShadow: "0px 10px 20px -5px #000000",
      }}
      justifycontent="flex-start"
      alignitems="flex-start"
    >
      {/* <Typography variant='h1' sx={{paddingTop:'1rem', paddingBottom:'0.5rem'}}>
                Podgląd instalacji
            </Typography> */}
      <StyledGridItem
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifycontent="flex-start"
        alignitems="flex-start"
        sx={{ padding: "1rem" }}
      >
        <StyledGridItem
          container
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          justifycontent="flex-start"
          alignitems="flex-start"
          sx={{
            flexDirection: !smallViewport ? "column" : "row",
            justifyContent: smallViewport && "flex-start",
          }}
        >
          {!smallViewport && <img src={SolarPanel} height={120} width="100%" />}
          <Box
            sx={{
              padding: smallViewport && "0.5rem",
              display: "flex",
              flexDirection: "column",
              paddingTop: "1rem",
            }}
          >
            <Typography variant="h2">{pv.description}</Typography>
            <Typography variant="h3">Nazwa instalacji</Typography>
          </Box>
          <Box
            sx={{
              padding: smallViewport && "0.5rem",
              display: "flex",
              flexDirection: "column",
              paddingTop: "1rem",
            }}
          >
            <Typography variant="h2">
              {pv.group ? pv.group.name : "Brak"}
            </Typography>
            <Typography variant="h3">Grupa</Typography>
          </Box>
          <Box
            sx={{
              padding: smallViewport && "0.5rem",
              display: "flex",
              flexDirection: "column",
              paddingTop: "1rem",
            }}
          >
            <Typography variant="h2">
              {pv.address_number} {pv.address_street} {pv.address_city}{" "}
              {pv.address_post_code}
            </Typography>
            <Typography variant="h3">Adres</Typography>
          </Box>
          <Box
            sx={{
              padding: smallViewport && "0.5rem",
              display: "flex",
              flexDirection: "column",
              paddingTop: "1rem",
            }}
          >
            <Typography variant="h2">
              {pv.pv_vendor} {pv.sn}
            </Typography>
            <Typography variant="h3">Falownik</Typography>
          </Box>
        </StyledGridItem>
        <StyledGridItem item lg={8} md={8} sm={12} xs={12}>
          {!smallViewport ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  {pv.status === "NO_CONNECTION" ? (
                    <BlockIcon
                      sx={{
                        color: theme.palette.primary.alert,
                        fontSize: "4rem",
                      }}
                    />
                  ) : (
                    <CellWifiIcon
                      sx={{
                        color: theme.palette.primary.success,
                        fontSize: "4rem",
                      }}
                    />
                  )}
                  {pv.status === "NO_CONNECTION" ? (
                    <Typography variant="h2">Offline</Typography>
                  ) : (
                    <Typography variant="h2">Online</Typography>
                  )}

                  <Typography variant="h3">Logger</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  {pv.status === "NO_CONNECTION" ||
                  pv.status === "INVALID_MESSAGE" ? (
                    <GridOffIcon
                      sx={{
                        color: theme.palette.primary.alert,
                        fontSize: "4rem",
                      }}
                    />
                  ) : (
                    <GridOnIcon
                      sx={{
                        color: theme.palette.primary.success,
                        fontSize: "4rem",
                      }}
                    />
                  )}
                  {pv.status === "NO_CONNECTION" ||
                  pv.status === "INVALID_MESSAGE" ? (
                    <Typography variant="h2">Offline</Typography>
                  ) : (
                    <Typography variant="h2">Online</Typography>
                  )}
                  <Typography variant="h3">Falownik</Typography>
                </Box>
              </Box>
              <EfficiencyProgress
                efficiency={efficiency}
                sizeMain={150}
                sizeSecondary={150}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  <SolarPowerIcon
                    sx={{
                      color: theme.palette.primary.yellow,
                      fontSize: "4rem",
                    }}
                  />
                  <Typography variant="h2">
                    {pv.last_power ? `${pv.last_power / 1000} kW` : "0 kW"}
                  </Typography>
                  <Typography variant="h3">Aktualna moc</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  <BoltIcon
                    sx={{ color: theme.palette.primary.main, fontSize: "4rem" }}
                  />
                  <Typography variant="h2">
                    {pv.generator_rated_power / 1000} kWp
                    {/* 10000 kWp */}
                  </Typography>
                  <Typography variant="h3">Moc paneli</Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%",
                    padding: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1rem",
                    }}
                  >
                    {pv.status === "NO_CONNECTION" ? (
                      <BlockIcon
                        sx={{
                          color: theme.palette.primary.alert,
                          fontSize: "4rem",
                        }}
                      />
                    ) : (
                      <CellWifiIcon
                        sx={{
                          color: theme.palette.primary.success,
                          fontSize: "4rem",
                        }}
                      />
                    )}
                    {pv.status === "NO_CONNECTION" ? (
                      <Typography variant="h2">Offline</Typography>
                    ) : (
                      <Typography variant="h2">Online</Typography>
                    )}

                    <Typography variant="h3">Logger</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1rem",
                    }}
                  >
                    {pv.status === "NO_CONNECTION" ||
                    pv.status === "INVALID_MESSAGE" ? (
                      <GridOffIcon
                        sx={{
                          color: theme.palette.primary.alert,
                          fontSize: "4rem",
                        }}
                      />
                    ) : (
                      <GridOnIcon
                        sx={{
                          color: theme.palette.primary.success,
                          fontSize: "4rem",
                        }}
                      />
                    )}
                    {pv.status === "NO_CONNECTION" ||
                    pv.status === "INVALID_MESSAGE" ? (
                      <Typography variant="h2">Offline</Typography>
                    ) : (
                      <Typography variant="h2">Online</Typography>
                    )}
                    <Typography variant="h3">Falownik</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-evenly",
                    padding: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1rem",
                    }}
                  >
                    <SolarPowerIcon
                      sx={{ color: "#BABA00", fontSize: "4rem" }}
                    />
                    <Typography variant="h2">
                      {pv.last_power ? `${pv.last_power / 1000} kW` : "0 kW"}
                    </Typography>
                    <Typography variant="h3">Aktualna moc</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1rem",
                    }}
                  >
                    <BoltIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: "4rem",
                      }}
                    />
                    <Typography variant="h2">
                      {pv.generator_rated_power / 1000} kWp
                      {/* 10000 kWp */}
                    </Typography>
                    <Typography variant="h3">Moc paneli</Typography>
                  </Box>
                </Box>
                <EfficiencyProgressMobile efficiency={efficiency} />
              </Box>
            </Box>
          )}
        </StyledGridItem>
      </StyledGridItem>

      {/* <StyledBox justifycontent='center' alignitems='center'>
            <Typography variant='h3'>
                Wybierz znacznik na mapie
            </Typography>
        </StyledBox> */}
    </StyledBox>
  );
};

export default Preview;
