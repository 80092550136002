import * as React from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  plPL,
  enUS,
  GridRenderCellParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  createTheme,
  ThemeProvider,
  styled,
  useTheme,
} from "@mui/material/styles";
import { format } from "date-fns";
import { Typography, Box, Button } from "@mui/material";
import { pl } from "date-fns/locale";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
    // border:'none',
    // borderRadius:'15px',
    padding: "0",
    // background:theme.palette.primary.dark,
    boxShadow: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    // background:theme.palette.primary.dark,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-menuIcon": {
    color: theme.palette.text.primary,
    background: "transparent",
  },
  //   '& .MuiSvgIcon-root':{
  //       color:theme.palette.text.primary,
  //       background:'transparent',
  //   }
}));

const UsersTable = ({
  handleOpenCard,
  usersData,
  handleUserData,
  handleSelectionModel,
  selectionModel,
  handleOpenRegister,
}) => {
  const theme = useTheme();

  //STATE OF SORTING//
  const [sortModel, setSortModel] = React.useState([
    {
      field: "is_active",
      sort: "desc",
    },
  ]);

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        sx={{
          color: theme.palette.text.tertiary,
          "& .MuiButtonBase-root": {
            // backgroundColor:theme.palette.primary.accent,
            color: theme.palette.text.tertiary,
            borderColor: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.accent,
            "&:hover": {
              backgroundColor: theme.palette.primary.accentActive,
            },
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.accentActive,
            "&.Mui-focusVisible": {
              background: theme.palette.primary.accentActive,
            },
          },
        }}
        // color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };
  const useStyles = makeStyles({
    root: {
      "& .header": {
        color: theme.palette.text.primary,
        //   background:theme.palette.primary.dark,
        "& > .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "& > .MuiDataGrid-menuIcon": {
          // background:theme.palette.primary.dark,
          color: theme.palette.text.primary,
        },
      },
    },
  });
  const classes = useStyles();
  const columns = [
    {
      field: "email",
      headerName: "Adres email",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: "Numer telefonu",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.formattedValue) {
          return params.formattedValue
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
          return "Brak";
        }
      },
      flex: 1,
    },
    // { field: 'number_of_pvs',
    // headerName: 'Ilość instalacji',
    // headerClassName:'header',
    // headerAlign:'center',
    // align:'center',
    // renderCell:(params: GridRenderCellParams) => {
    //   return params.formattedValue
    // },

    // flex:1},
    {
      field: "is_active",

      headerName: "Status konta",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        //
        if (params.formattedValue === false) {
          return (
            <span style={{ color: theme.palette.primary.alert }}>
              Nieaktywne
            </span>
          );
        }
        if (params.formattedValue === true) {
          return (
            <span style={{ color: theme.palette.primary.success }}>
              Aktywne
            </span>
          );
        }
      },
      flex: 1,
    },
    {
      field: "last_login",
      sort: "asc",
      headerName: "Ostatnie logowanie",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        //
        if (params.formattedValue) {
          return format(new Date(params.formattedValue), "HH:mm dd/MM/yyyy", {
            locale: pl,
          });
        }
      },
      flex: 1,
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        background: theme.palette.primary.dark,
        flexDirection: "column",
        boxShadow: "0px 10px 20px -5px #000000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          onClick={handleOpenRegister}
          endIcon={<PersonAddIcon sx={{ height: "2em", width: "2em" }} />}
        >
          Zarejestruj nowego Klienta
        </Button>
      </Box>
      <div style={{ flexGrow: 1 }}>
        {/* <ThemeProvider theme={myTheme}> */}
        <StyledDataGrid
          components={{
            Pagination: CustomPagination,
          }}
          // sx={{
          //   backgroundColor:'transparent',
          //   // border:'none',

          //   '& .cell':{
          //     color:theme.palette.text.primary,
          //     fontSize:'1rem',
          //   },
          //   '& .alert':{
          //     color:theme.palette.primary.alert,
          //     fontSize:'1rem',
          //   }
          // }}
          // getCellClassName={(params)=>{
          //   if (params.field === 'deviation') {
          //     return 'alert'
          //   }
          //   else {

          //     return 'cell'
          //   }
          // }}
          // disableSelectionOnClick={true}\
          onRowClick={(val) => {
            handleUserData(val.row);
            handleOpenCard();
          }}
          hideFooterSelectedRowCount={true}
          className={classes.root}
          pageSize={20}
          rowsPerPageOptions={[20]}
          pagination
          disableExtendRowFullWidth={false}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onSelectionModelChange={(newSelectionModel) => {
            handleSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          rows={usersData}
          // autoHeight={true}
          columns={columns}
        />
        {/* </ThemeProvider> */}
      </div>
    </div>
  );
};
export default UsersTable;
