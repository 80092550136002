import * as React from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  plPL,
  enUS,
  GridRenderCellParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  createTheme,
  ThemeProvider,
  styled,
  useTheme,
} from "@mui/material/styles";
import { CallToActionSharp } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { Typography } from "@mui/material";
import { pl } from "date-fns/locale";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useNavigate } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
    // border:'none',
    // borderRadius:'15px',
    padding: "0",
    // background:theme.palette.primary.dark,
    boxShadow: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    // background:theme.palette.primary.dark,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-menuIcon": {
    color: theme.palette.text.primary,
    background: "transparent",
  },
  //   '& .MuiSvgIcon-root':{
  //       color:theme.palette.text.primary,
  //       background:'transparent',
  //   }
}));

const tempData = [
  {
    id: 1,
    group: "Pomorskie",
    problems: "4",
    offline: "1",
  },
];

export default function RecentOvervoltages({ weeklyAlerts, isLoading }) {
  //
  const theme = useTheme();
  const myTheme = createTheme(plPL);
  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        sx={{
          color: theme.palette.text.primary,
          "& .MuiButtonBase-root": {
            // backgroundColor:theme.palette.primary.accent,
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.secondary,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.dark,
            "&.Mui-focusVisible": {
              background: theme.palette.primary.main,
              color: theme.palette.primary.dark,
            },
          },
        }}
        // color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };
  const useStyles = makeStyles({
    root: {
      "& .header": {
        color: theme.palette.text.primary,
        //   background:theme.palette.primary.dark,
        "& > .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "& > .MuiDataGrid-menuIcon": {
          // background:theme.palette.primary.dark,
          color: theme.palette.text.primary,
        },
      },
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();
  const columns = [
    {
      field: "name",
      headerName: "Grupa",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",

      flex: 1,
    },
    {
      field: "events",
      headerName: "Problemy (ostatnie 7 dni)",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.formattedValue === 0) {
          return (
            <span style={{ color: theme.palette.primary.success }}>
              {params.formattedValue}
            </span>
          );
        } else {
          return (
            <span style={{ color: theme.palette.primary.alert }}>
              {params.formattedValue}
            </span>
          );
        }
      },
      flex: 1,
    },
    {
      field: "invalid_message",
      headerName: "Falowniki offline",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.formattedValue === 0) {
          return (
            <span style={{ color: theme.palette.primary.success }}>
              {params.formattedValue}
            </span>
          );
        } else {
          return (
            <span style={{ color: theme.palette.primary.alert }}>
              {params.formattedValue}
            </span>
          );
        }
      },
      flex: 1,
    },
    {
      field: "no_connection",
      headerName: "Modemy offline",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.formattedValue === 0) {
          return (
            <span style={{ color: theme.palette.primary.success }}>
              {params.formattedValue}
            </span>
          );
        } else {
          return (
            <span style={{ color: theme.palette.primary.alert }}>
              {params.formattedValue}
            </span>
          );
        }
      },
      flex: 1,
    },
    {
      field: "working",
      headerName: "Poprawnie pracuje",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        background: theme.palette.primary.dark,
        flexDirection: "column",
        boxShadow: "0px 10px 20px -5px #000000",
      }}
    >
      <Typography
        variant="h1"
        sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        Podsumowanie
      </Typography>
      <div style={{ flexGrow: 1 }}>
        {/* <ThemeProvider theme={myTheme}> */}
        <StyledDataGrid
          components={{
            Pagination: CustomPagination,
          }}
          sx={{
            backgroundColor: "transparent",
            border: "none",
            "& .cell": {
              color: theme.palette.text.primary,
              fontSize: "1rem",
            },
            "& .alert": {
              color: theme.palette.primary.alert,
              fontSize: "1rem",
            },
          }}
          getCellClassName={(params) => {
            if (params.field === "deviation") {
              return "alert";
            } else {
              return "cell";
            }
          }}
          hideFooterSelectedRowCount={true}
          className={classes.root}
          // disableSelectionOnClick={true}
          onRowClick={(row) => {
            navigate(`/groups/${row.id}`);
          }}
          pageSize={20}
          rowsPerPageOptions={[20]}
          pagination
          disableExtendRowFullWidth={false}
          rows={weeklyAlerts}
          // autoHeight={true}
          columns={columns}
          loading={isLoading}
        />
        {/* </ThemeProvider> */}
      </div>
    </div>
  );
}
