import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import UsersTable from "../components/UsersTable";
import UserCard from "../components/UserCard";
import GetUserList from "../api/GetUserList";
import CustomSnackBar from "../components/CustomSnackBar";
import CustomCircularProgress from "../components/CustomCircularProgress";
import RegisterFlowUser from "../components/RegisterFlowUser";

const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent }) => ({
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: "90vh",
  })
);

const UsersManagement = () => {
  const theme = useTheme();
  const [hidden, setHidden] = useState(true);
  const [pickedRow, setPickedRow] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [usersData, setUsersData] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [forceTableReload, setForceTableReload] = useState(false);

  const handleForceTableReload = () => {
    setForceTableReload((prev) => !prev);
  };
  const handleSnackBarText = (val) => {
    setSnackBarText(val);
  };
  const handleOpenRegister = () => {
    setOpenRegister(true);
  };
  const handleCloseRegister = () => {
    setOpenRegister(false);
  };
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  useEffect(() => {
    const handleOnLoad = async () => {
      setHidden(true);
      setLoading(true);
      let response = await GetUserList();
      if (response) {
        if (response.status === 200) {
          setUsersData(
            response.data.map((e, i) => {
              return { ...e, id: i };
            })
          );
          setLoading(false);
        }
      }
    };
    handleOnLoad();
  }, [forceTableReload]);
  const handleOpenCard = () => {
    setHidden(false);
  };
  const handleCloseCard = () => {
    setHidden(true);
  };
  const handleUserData = (value) => {
    setPickedRow(value);
  };
  //SELECTION MODEL CONTROLS STATE OF CURRENTLY SELECTED ROW//
  const handleSelectionModel = (val) => {
    setSelectionModel(val);
  };
  return (
    <StyledGridItem item container lg={12} md={12} sm={12} xs={12}>
      <StyledGridItem
        item
        lg={hidden ? 12 : 8}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        sx={{ padding: "1rem" }}
      >
        {loading ? (
          <CustomCircularProgress
            size={120}
            position="absolute"
            top="50%"
            left="50%"
            color={theme.palette.primary.dark}
          />
        ) : (
          usersData && (
            <UsersTable
              handleOpenRegister={handleOpenRegister}
              handleSelectionModel={handleSelectionModel}
              selectionModel={selectionModel}
              handleUserData={handleUserData}
              usersData={usersData}
              handleOpenCard={handleOpenCard}
            />
          )
        )}
      </StyledGridItem>
      {!hidden && (
        <StyledGridItem
          item
          lg={4}
          md={12}
          sm={12}
          xs={12}
          alignitems="center"
          justifycontent="center"
          sx={{ padding: "1rem" }}
        >
          <UserCard
            handleSnackBarText={handleSnackBarText}
            handleOpenSnackBar={handleOpenSnackBar}
            handleSelectionModel={handleSelectionModel}
            handleCloseCard={handleCloseCard}
            pickedRow={pickedRow}
            handleForceTableReload={handleForceTableReload}
          />
        </StyledGridItem>
      )}
      <RegisterFlowUser
        handleSnackBarText={handleSnackBarText}
        handleOpenSnackBar={handleOpenSnackBar}
        openRegister={openRegister}
        handleCloseRegister={handleCloseRegister}
        handleForceTableReload={handleForceTableReload}
      />
      <CustomSnackBar
        text={snackBarText}
        handleCloseSnackBar={handleCloseSnackBar}
        openSnackBar={openSnackBar}
      />
    </StyledGridItem>
  );
};

export default UsersManagement;
