import { useNavigate, useLocation, Link } from "react-router-dom";
import * as React from "react";
import { useAuth } from "../funcs/useAuth";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import LoginIcon from "@mui/icons-material/Login";
import SolarPanelBg from "../constants/SolarPanelBg.jpg";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import logo from "../constants/logo.svg";
const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height, flexdirection }) => ({
    display: "flex",
    alignItems: alignitems,
    justifyContent: justifycontent,
    flexDirection: flexdirection,
    height: height,
    flexGrow: 1,
  })
);

const LoginPage = () => {
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [authFailCode, setAuthFailCode] = React.useState(0);
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { state } = useLocation();
  const handleLogin = async () => {
    setAuthFailCode(0);
    setIsLoading(true);
    login(username, password, remember)
      .then((res) => {
        navigate(state?.path || "/");
      })
      .catch((err) => {
        setAuthFailCode(err.response.status);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUsernameChange = (event) => {
    setUserName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };

  const handleEnterPressed = (event) => {
    if (event.keyCode === 13) {
      // enter keyCode
      handleLogin();
    }
  };

  return (
    <StyledGridItem
      container
      height="100vh"
      width="100vw"
      justifyContent="space-between"
      spacing={0}
    >
      {/* <StyledGridItem
        item
        container
        lg={6}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
        height="100vh"
        flexdirection="column"
        alignItems="center"
        spacing={0}
        sx={{ backgroundColor: theme.palette.primary.dark }}
      >
        <img src={logo} />
      </StyledGridItem> */}
      <StyledGridItem
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
        height="100vh"
        flexdirection="column"
        alignItems="center"
        spacing={0}
        sx={{
          backgroundImage: `url(${SolarPanelBg})`,
          backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",

          backgroundPositionY: "50%",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "3rem",
          }}
        >
          <img src={logo} alt="Logo" style={{ height: 150 }} />
          <Typography
            variant="h1"
            sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            Zaloguj się
          </Typography>
          <FormControl
            sx={{
              width: "20rem",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <TextField
              sx={{
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
              id="outlined-basic"
              onKeyDown={handleEnterPressed}
              value={username}
              onChange={handleUsernameChange}
              placeholder="Adres email"
              variant="outlined"
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              sx={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
              id="outlined-basic"
              onKeyDown={handleEnterPressed}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Hasło"
              variant="outlined"
              type="password"
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: theme.palette.text.secondary }} />
                  </InputAdornment>
                ),
              }}
            />

            <Link
              to="/forgotPassword"
              style={{
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                textDecoration: "none",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  "&:hover": {
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                  },
                }}
              >
                Zapomniałem hasła
              </Typography>
            </Link>
            <FormControlLabel
              sx={{ alignSelf: "center" }}
              control={
                <Checkbox checked={remember} onChange={handleRememberChange} />
              }
              label={
                <Typography
                  variant="h4"
                  sx={{
                    "&:hover": {
                      color: theme.palette.text.primary,
                      fontWeight: "bold",
                    },
                  }}
                >
                  Zapamiętaj mnie na 14 dni
                </Typography>
              }
            />
            <Button
              sx={{
                borderRadius: "25px",
                margin: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                "& .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  "& .MuiSvgIcon-root": {
                    transition: "transform 0.2s",
                    transform: "translateX(5px)",
                  },
                },
              }}
              variant="contained"
              disabled={isLoading}
              type="submit"
              endIcon={<LoginIcon />}
            >
              Zaloguj
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>

            <Typography variant="h4" sx={{ marginTop: "2rem" }}>
              Nie masz jeszcze konta?
            </Typography>
            <Link
              style={{ paddingBottom: "0.5rem", textDecoration: "none" }}
              to="/register"
            >
              <Typography
                variant="h2"
                sx={{
                  "&:hover": {
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                  },
                }}
              >
                Zarejestruj się
              </Typography>
            </Link>

            {authFailCode === 0 ? (
              <></>
            ) : authFailCode === 401 ? (
              <span style={{ color: "red" }}>Nieprawidłowe dane logowania</span>
            ) : authFailCode >= 500 && authFailCode < 600 ? (
              <span style={{ color: "red" }}>Błąd po stronie serwera</span>
            ) : (
              <span style={{ color: "red" }}>
                Niestandardowy błąd logowania
              </span>
            )}
          </FormControl>
        </Paper>
      </StyledGridItem>
    </StyledGridItem>
  );
};
export default LoginPage;

// <div>
//   <h1>Login</h1>
//   <button onClick={handleLogin}>Log in</button>
// </div>
