import { CircularProgress, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetGeoJson from "../api/GetGeoJSON";
import GetMarkers from "../api/GetMarkers";
import GetWeeklyAlerts from "../api/GetWeeklyAlerts";
import GetWeeklyOvervoltages from "../api/GetWeeklyOvervoltages";
import MemoMap from "../components/Map";
import Preview from "../components/Preview";
import RecentOvervoltages from "../components/RecentOvervoltages";
import RecentProblems from "../components/RecentProblems";

const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height }) => ({
    alignItems: alignitems,
    justifyContent: justifycontent,
    height: height,
    flexGrow: 1,
  })
);

const Dashboard = () => {
  const theme = useTheme();
  const [geoJson, setGeoJson] = useState();
  const [loading, setLoading] = useState(false);
  const [markersData, setMarkersData] = useState();
  const [pv, setPv] = useState("");
  const [efficiency, setEfficiency] = useState(0);
  const [parsedGeoJson, setParsedGeoJson] = useState("");
  const [weeklyAlerts, setWeeklyAlerts] = useState(``);
  const [weeklyOvervoltages, setWeeklyOvervoltages] = useState(``);
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [overvoltagesLoading, setOvervoltagesLoading] = useState(false);
  let { groupId } = useParams();

  const handleSetPv = (value) => {
    setPv(value);

    if (value.last_power) {
      setEfficiency((value.last_power / value.generator_rated_power) * 100);
    } else {
      setEfficiency(0);
    }
  };
  useEffect(() => {
    //
    //
    const handleOnLoad = async () => {
      //
      setLoading(true);
      setOvervoltagesLoading(true);
      setAlertsLoading(true);
      let responseMap = await GetGeoJson();
      let markersResponse = await GetMarkers();
      GetWeeklyAlerts()
        .then((res) => {
          if (res && res.data) {
            //

            setWeeklyAlerts(res.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setAlertsLoading(false);
        });
      GetWeeklyOvervoltages()
        .then((res) => {
          if (res && res.data) {
            let temp = res.data.map((val, i) => {
              return { ...val, id: i + 1 };
            });

            setWeeklyOvervoltages(temp);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setOvervoltagesLoading(false);
        });

      if (responseMap && markersResponse) {
        setGeoJson(responseMap.data);
        setMarkersData(markersResponse.data);
        setLoading(false);
      }
    };
    handleOnLoad();
  }, []);

  ///MAP////
  const handleStatus = () => {
    let temp = {
      type: "FeatureCollection",
      features: [],
    };
    //
    if (geoJson && markersData) {
      geoJson.features.map((value, index) => {
        if (value.geometry.coordinates[0] !== null) {
          markersData.map((v, i) => {
            //
            if (v.id === value.properties.object_id) {
              //
              temp.features.push({
                ...value,
                properties: {
                  ...value.properties,
                  status:
                    v.status === "WORKING"
                      ? "WORKING"
                      : v.status === "INVALID_MESSAGE"
                      ? "INVALID_MESSAGE"
                      : "NO_CONNECTION",
                },
                id: i + 1,
              });
            }
          });
        }
      });
      return temp;
    }
  };

  useEffect(() => {
    const handleParse = () => {
      //
      //
      setParsedGeoJson(handleStatus());
    };
    handleParse();
  }, [geoJson, markersData]);

  const mediumViewport = useMediaQuery("(max-width:1400px)");
  const smallViewport = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledGridItem
      item
      container
      lg={12}
      md={12}
      sm={12}
      xs={12}
      justifyContent="space-between"
      spacing={2}
    >
      <StyledGridItem
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        height={mediumViewport ? "60vh" : "45vh"}
      >
        {loading ? (
          <CircularProgress
            size={120}
            sx={{
              color: theme.palette.primary.dark,
              position: "relative",
              top: "40%",
              left: "40%",
            }}
          />
        ) : (
          parsedGeoJson &&
          markersData && (
            <MemoMap
              handleSetPv={handleSetPv}
              geoJson={parsedGeoJson}
              markersData={markersData}
            />
          )
        )}
      </StyledGridItem>
      <StyledGridItem
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        height={smallViewport ? "80vh" : mediumViewport ? "60vh" : "45vh"}
      >
        {pv ? (
          <Preview efficiency={efficiency} pv={pv} />
        ) : (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                color: theme.palette.text.tertiary,
                textAlign: "center",
              }}
            >
              Wybierz znacznik na mapie, by wyświetlić bieżące dane instalacji
            </Typography>
          </Box>
        )}
      </StyledGridItem>
      <StyledGridItem
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        height={smallViewport ? "80vh" : mediumViewport ? "60vh" : "45vh"}
      >
        <RecentProblems weeklyAlerts={weeklyAlerts} isLoading={alertsLoading} />
      </StyledGridItem>
      <StyledGridItem
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        alignitems="center"
        justifycontent="center"
        height={smallViewport ? "80vh" : mediumViewport ? "60vh" : "45vh"}
      >
        <RecentOvervoltages
          weeklyOvervoltages={weeklyOvervoltages}
          isLoading={overvoltagesLoading}
        />
      </StyledGridItem>
    </StyledGridItem>
  );
};

export default Dashboard;
