import { useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { useAuth } from "../funcs/useAuth";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Paper, Typography, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import PostRegisterCareUser from "../api/PostRegisterCareUser";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import KeyIcon from "@mui/icons-material/Key";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height, flexdirection }) => ({
    display: "flex",
    alignItems: alignitems,
    justifyContent: justifycontent,
    flexDirection: flexdirection,
    height: height,
    flexGrow: 1,
  })
);

const RegisterPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [repeatedPassword, setRepeatedPassword] = React.useState("");
  const [licenceKey, setLicenceKey] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const { state } = useLocation();
  const theme = useTheme();
  const handlerRegister = async () => {
    setIsLoading(true);
    PostRegisterCareUser(email, password, repeatedPassword, licenceKey)
      .then((res) => {
        if (res.status === 201) {
          navigate("/");
        }
      })
      .catch((err) => {
        const res = err.response.data;
        if (res.email) {
          setErrorMessage("Użytkownik z tym adresem email już istnieje");
        } else if (res.password_1) {
          setErrorMessage("Podane hasło nie spełnia wymagań");
        } else if (res.password_2) {
          setErrorMessage("Pola haseł różnią się od siebie");
        } else if (res.license_key) {
          setErrorMessage("Klucz licencyjny jest nieprawidłowy");
        } else {
          setErrorMessage("Rejestracja zakończona niepowodzeniem");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRepeatedPasswordChange = (event) => {
    setRepeatedPassword(event.target.value);
  };

  const handleLicenceKeyChange = (event) => {
    setLicenceKey(event.target.value);
  };

  const handleEnterPressed = (event) => {
    if (event.keyCode === 13) {
      // enter keyCode
      handleRepeatedPasswordChange();
    }
  };

  const handleGoBack = (event) => {
    navigate("/login");
  };

  return (
    <StyledGridItem
      container
      height="100vh"
      width="100vw"
      justifyContent="space-between"
      sx={{ backgroundColor: theme.palette.primary.main }}
      spacing={0}
    >
      <StyledGridItem
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
        height="100vh"
        flexdirection="column"
        alignItems="center"
        spacing={0}
        sx={{
          // backgroundImage: `url(${SolarPanelBg})`,
          backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",

          backgroundPositionY: "50%",
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            color: theme.palette.text.tertiary,
            "&:hover": {
              fontWeight: "bold",
            },
          }}
          onClick={handleGoBack}
        >
          Powrót do strony logowania
        </Button>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "3rem",
          }}
        >
          <Typography
            variant="h1"
            sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            Utwórz nowe konto
          </Typography>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              onKeyDown={handleEnterPressed}
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              placeholder="Adres email"
              sx={{ margin: "0.4rem" }}
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              onKeyDown={handleEnterPressed}
              value={password}
              onChange={handlePasswordChange}
              variant="outlined"
              type="password"
              sx={{ margin: "0.4rem" }}
              placeholder="Hasło"
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: theme.palette.text.secondary }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              onKeyDown={handleEnterPressed}
              value={repeatedPassword}
              onChange={handleRepeatedPasswordChange}
              variant="outlined"
              type="password"
              sx={{ margin: "0.4rem" }}
              placeholder="Potwierdź hasło"
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: theme.palette.text.secondary }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              onKeyDown={handleEnterPressed}
              value={licenceKey}
              onChange={handleLicenceKeyChange}
              variant="outlined"
              sx={{ margin: "0.4rem" }}
              placeholder="Klucz licencyjny"
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon sx={{ color: theme.palette.text.secondary }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                borderRadius: "25px",
                margin: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                "& .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  "& .MuiSvgIcon-root": {
                    transition: "transform 0.2s",
                    transform: "translateX(5px)",
                  },
                },
              }}
              variant="contained"
              endIcon={<ArrowForwardIosIcon />}
              onClick={handlerRegister}
            >
              Zarejestruj
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </FormControl>

          {errorMessage === "" ? (
            <></>
          ) : (
            <span style={{ color: theme.palette.primary.alert }}>
              {errorMessage}
            </span>
          )}
        </Paper>
      </StyledGridItem>
    </StyledGridItem>
  );
};
export default RegisterPage;
