import { api } from "./../funcs/AuthManager";
import format from "date-fns/format";
async function GetPower(id, currentDate) {
  return api
    .get(`/api/v1/power_data/${id}/`, {
      params: {
        date: format(currentDate, "yyyy-MM-dd"),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export default GetPower;
