import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Paper, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GetFlowPvsList from "../api/GetFlowPvsList";
import Cookies from "universal-cookie";
import FlowPvsList from "./FlowPvsList";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PlugPVToUser from "./PlugPVToUser";
import CustomCircularProgress from "./CustomCircularProgress";
import SendIcon from "@mui/icons-material/Send";
import ActivationReminder from "../api/ActivationReminder";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteUserFlow from "../api/DeleteUserFlow";
import DeteleUserDialog from "./DeleteUserDialog";
const cookies = new Cookies();

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: theme.palette.primary.dark,
  borderRadius: 0,
  boxShadow: "0px 10px 20px -5px #000000",
}));

const UserCard = ({
  pickedRow,
  handleCloseCard,
  handleSelectionModel,
  handleOpenSnackBar,
  handleSnackBarText,
  handleForceTableReload,
}) => {
  const theme = useTheme();
  const [userPvs, setUserPvs] = useState(``);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forcedReload, setForcedReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = React.useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletePending, setDeletePending] = useState(false);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  ///Open "PlugPVToUser" dialog component
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleForceReload = () => {
    setForcedReload((prev) => !prev);
    handleSnackBarText("Zmiany zapisane pomyślnie");
    handleOpenSnackBar();
  };
  const handleDeleteUserFlow = async (value) => {
    setDeletePending(true);
    DeleteUserFlow(value)
      .then((res) => {
        handleSnackBarText("Użytkownik usunięty pomyślnie");
        handleOpenSnackBar(true);
        handleCloseDeleteDialog();
        handleForceTableReload();
      })
      .catch((err) => {
        setErrorCode(err.response.status);
      })
      .finally(() => {
        setDeletePending(false);
      });
  };
  const handleSendLink = async (value) => {
    setIsLoading(true);
    ActivationReminder(value)
      .then((res) => {
        handleSnackBarText(
          "Email z linkiem aktywacyjnym został wysłany na adres Klienta"
        );
        handleOpenSnackBar(true);
      })
      .catch((err) => {
        setErrorCode(err.response.status);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const handleChangeCard = async () => {
      setLoading(true);
      let response = await GetFlowPvsList(pickedRow.email);
      if (response) {
        setUserPvs(response.data);
        setLoading(false);
      }
    };
    handleChangeCard();
  }, [pickedRow, forcedReload]);

  return (
    <StyledPaper sx={{ overflow: "auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" sx={{ padding: "1rem" }}>
          {pickedRow.email}
        </Typography>
        <IconButton
          onClick={() => {
            handleSelectionModel("");
            handleCloseCard();
          }}
          sx={{ padding: "1rem" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {pickedRow.is_active ? (
          <Typography
            variant="h2"
            sx={{ paddingLeft: "1rem", color: theme.palette.primary.success }}
          >
            Konto aktywne
          </Typography>
        ) : (
          <Typography
            variant="h2"
            sx={{ paddingLeft: "1rem", color: theme.palette.primary.alert }}
          >
            Konto nieaktywne
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "50%",
          }}
        >
          {!pickedRow.is_active && (
            <Button
              sx={{
                borderRadius: "25px",
                margin: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                "& .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  "& .MuiSvgIcon-root": {
                    transition: "transform 0.2s",
                    transform: "translateX(5px)",
                  },
                },
              }}
              disabled={isLoading}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => handleSendLink(pickedRow.email)}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.palette.text.tertiary }}
              >
                Wyślij link do aktywacji
              </Typography>

              {isLoading && (
                <CustomCircularProgress
                  size={24}
                  position="absolute"
                  top="50%"
                  left="50%"
                  marginTop="-12px"
                  marginLeft="-12px"
                />
              )}
            </Button>
          )}
          <Button
            sx={{
              borderRadius: "25px",
              margin: "0.5rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              backgroundColor: theme.palette.primary.alert,
              "& .MuiSvgIcon-root": {
                transform: "translateX(-5px)",
              },
              "&:hover": {
                backgroundColor: theme.palette.primary.alert,
                "& .MuiSvgIcon-root": {
                  transition: "transform 0.2s",
                  transform: "translateX(5px)",
                },
              },
            }}
            disabled={isLoading}
            variant="contained"
            endIcon={<DeleteIcon />}
            onClick={() => handleClickOpenDeleteDialog()}
          >
            <Typography
              variant="h4"
              sx={{ color: theme.palette.text.tertiary }}
            >
              Usuń użytkownika
            </Typography>

            {isLoading && (
              <CustomCircularProgress
                size={24}
                position="absolute"
                top="50%"
                left="50%"
                marginTop="-12px"
                marginLeft="-12px"
              />
            )}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography variant="h1" sx={{ padding: "1rem" }}>
          Instalacje Klienta
        </Typography>
        <Button
          endIcon={<AddCircleIcon />}
          onClick={handleClickOpen}
          sx={{ padding: "1rem" }}
        >
          <Typography variant="h3"></Typography>
          Dodaj nową
        </Button>
      </Box>
      {loading ? (
        <CustomCircularProgress
          size={60}
          position="relative"
          top="10%"
          left="50%"
          color={theme.palette.primary.main}
        />
      ) : userPvs && userPvs.length > 0 ? (
        <FlowPvsList userPvs={userPvs} />
      ) : (
        <Typography
          variant="h1"
          sx={{ padding: "1rem", color: "rgba(0,0,0,0.5)" }}
        >
          Brak instalacji przypisanych do Klienta
        </Typography>
      )}
      <PlugPVToUser
        userEmail={pickedRow.email}
        open={open}
        handleClose={handleClose}
        handleForceReload={handleForceReload}
      />
      <DeteleUserDialog
        deletePending={deletePending}
        handleDeleteUserFlow={handleDeleteUserFlow}
        userEmail={pickedRow.email}
        openDeleteDialog={openDeleteDialog}
        errorCode={errorCode}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
      />
    </StyledPaper>
  );
};

export default UserCard;
