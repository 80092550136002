import React from "react";
import { CircularProgress } from "@mui/material";

const CustomCircularProgress = ({
  size,
  color,
  position,
  top,
  left,
  marginTop,
  marginLeft,
}) => {
  return (
    <CircularProgress
      size={size}
      sx={{
        color: color,
        position: position,
        top: top,
        left: left,
        marginTop: marginTop,
        marginLeft: marginLeft,
      }}
    />
  );
};

export default CustomCircularProgress;
