import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import format from "date-fns/format";
import SaveIcon from "@mui/icons-material/Save";
import PostTaskOrder from "../api/PostTaskOrder";
const drawerWidth = 450;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AddTaskDialog = ({
  openDrawer,
  handleDrawerClose,
  pv,
  handleSnackBarText,
  handleOpenSnackBar,
}) => {
  const theme = useTheme();
  const [formData, setFormData] = React.useState({
    description: pv.description,
    inverter_sn: pv.sn,
    contact: pv.user ? pv.user.email : "Brak danych",
    creation_date: new Date(),
    employee: "",
    adress: `${pv.address_street} ${pv.address_number}${[
      pv.address_number_2 && [`/${pv.address_number_2}`],
    ]}, ${pv.address_city} ${pv.address_post_code}`,
    reason: "",
    order: "",
  });
  const handlePostGroup = async () => {
    if (formData) {
      PostTaskOrder(formData)
        .then((res) => {
          if (res && res.data) {
            handleSnackBarText("Zgłoszenie dodane pomyślnie");
            handleOpenSnackBar();
            handleDrawerClose();
          }
        })
        .catch((err) => {})
        .finally(() => {
          setFormData({ ...formData, employee: "", reason: "", order: "" });
        });
    }
  };
  const handleStringInput = (event) => {
    event.preventDefault();
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  return (
    <Box
      sx={
        openDrawer
          ? {
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.3)",
            }
          : {}
      }
      onClick={handleDrawerClose}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDrawer}
        onClick={(event) => {
          if (!event) var event = window.event;
          event.cancelBubble = true;
          if (event.stopPropagation) event.stopPropagation();
        }}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Nowe zgłoszenie</Typography>
          <Button onClick={handleDrawerClose}>
            <Typography variant="h3">Anuluj</Typography>

            <ChevronRightIcon />
          </Button>
        </DrawerHeader>
        <Divider
          fullWidth
          flexItem={true}
          orientation="horizontal"
          sx={{
            backgroundColor: theme.palette.primary.accent,
            height: "4px",
          }}
        />
        <Box
          component="form"
          sx={{
            //   width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                padding: "0.25rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
              variant="h2Alt"
            >
              Dane instalacji
            </Typography>
            <Typography
              sx={{
                padding: "0.25rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
              variant="h3Alt"
            >
              {pv.description}
            </Typography>
            <Typography
              sx={{
                padding: "0.25rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
              variant="h3Alt"
            >
              {pv.address_street} {pv.address_number}
              {pv.address_number_2 && `/${pv.address_number_2}`}{" "}
              {pv.address_city} {pv.address_post_code}
            </Typography>
            <Typography
              sx={{
                padding: "0.25rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
              variant="h3Alt"
            >
              {pv.pv_vendor} {pv.sn}
            </Typography>
            <Typography
              sx={{
                padding: "0.25rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
              variant="h3Alt"
            >
              {pv.user ? pv.user.email : "Brak przypisanego Klienta"}
            </Typography>
          </Box>
          <Divider
            flexItem={true}
            orientation="horizontal"
            sx={{
              backgroundColor: theme.palette.primary.accent,
              height: "1px",
              width: "90%",
            }}
          />
          <Typography
            sx={{
              padding: "0.25rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}
            variant="h2Alt"
          >
            Uzupełnij formularz zgłoszenia
          </Typography>
          <Typography
            sx={{
              padding: "0.25rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}
            variant="h3Alt"
          >
            Data utworzenia: {format(new Date(), "dd-MM-yyyy")}
          </Typography>
          <TextField
            sx={{ paddingTop: "1rem" }}
            id="outlined-basic"
            placeholder="Pracownik"
            variant="outlined"
            fullWidth
            required={true}
            name="employee"
            helperText="Kto ma się zająć zgłoszeniem?"
            value={formData.employee}
            onChange={handleStringInput}
            InputProps={{
              sx: { borderRadius: "25px" },
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ paddingTop: "1rem" }}
            id="outlined-basic"
            placeholder="Powód zgłoszenia"
            variant="outlined"
            fullWidth
            name="reason"
            required={true}
            helperText="Jaki jest powód zgłoszenia?"
            value={formData.reason}
            onChange={handleStringInput}
            InputProps={{
              sx: { borderRadius: "25px" },
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ paddingTop: "1rem" }}
            id="outlined-basic"
            placeholder="Uwagi"
            fullWidth
            variant="outlined"
            name="order"
            helperText="Co należy zrobić?"
            required={true}
            value={formData.order}
            multiline={true}
            onChange={handleStringInput}
            InputProps={{
              sx: { borderRadius: "25px" },
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={{
              borderRadius: "25px",
              margin: "0.5rem",
              fontWeight: "bold",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              border: "solid",
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: "transparent",
              "& .MuiSvgIcon-root": {
                transform: "translateX(-5px)",
              },
              "&:hover": {
                backgroundColor: "transparent",
                "& .MuiSvgIcon-root": {
                  transition: "transform 0.2s",
                  transform: "translateX(5px)",
                },
              },
            }}
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handlePostGroup}
          >
            Zapisz
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};
export default AddTaskDialog;
