import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CustomCircularProgress from "./CustomCircularProgress";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
const DeteleUserDialog = ({
  openDeleteDialog,
  handleCloseDeleteDialog,
  userEmail,
  handleDeleteUserFlow,
  deletePending,
  errorCode,
}) => {
  const theme = useTheme();
  const [emailConfirm, setEmailConfirm] = React.useState("");
  const [errorPrompt, setErrorPrompt] = React.useState("");
  const handleCheckEmails = (event) => {
    setEmailConfirm(event.target.value);
  };
  React.useEffect(() => {
    setEmailConfirm("");
  }, []);
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => {
        setErrorPrompt("");
        setEmailConfirm("");
        handleCloseDeleteDialog();
      }}
      PaperProps={{ sx: { minWidth: "30rem" } }}
    >
      <DialogTitle>
        Czy na pewno chcesz usunąć użytkownika{" "}
        <span style={{ fontWeight: "bold" }}>{userEmail}</span>?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aby potwierdzić operację, wpisz ponownie adres email konta
          użytkownika, którego chcesz usunąć
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={emailConfirm}
          label="Adres email"
          type="email"
          onChange={handleCheckEmails}
          fullWidth
          variant="outlined"
          InputProps={{
            sx: { borderRadius: "25px" },
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmailIcon
                  sx={{ color: theme.palette.text.secondary }}
                />
              </InputAdornment>
            ),
          }}
        />
        <span style={{ color: theme.palette.primary.alert }}>
          {errorPrompt}
        </span>
        {errorCode === 0 ? (
          <></>
        ) : errorCode === 400 ? (
          <Typography sx={{ color: theme.palette.primary.alert }}>
            Złe dane wejściowe
          </Typography>
        ) : errorCode >= 500 && errorCode < 600 ? (
          <Typography sx={{ color: theme.palette.primary.alert }}>
            Błąd po stronie serwera
          </Typography>
        ) : (
          <Typography sx={{ color: theme.palette.primary.alert }}>
            Niestandardowy błąd usuwania użytkownika
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setEmailConfirm("");
            setErrorPrompt("");
            handleCloseDeleteDialog();
          }}
        >
          Anuluj
        </Button>
        <Button
          disabled={deletePending}
          sx={{
            borderRadius: "25px",
            margin: "0.5rem",
            position: "relative",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            "& .MuiSvgIcon-root": {
              transform: "translateX(-5px)",
            },
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="contained"
          onClick={() => {
            setErrorPrompt("");
            if (emailConfirm === userEmail) {
              handleDeleteUserFlow(userEmail);
            } else {
              setErrorPrompt(
                "Wprowadzony adres email nie pokrywa się z adresem email użytkownika"
              );
            }
          }}
        >
          Potwierdź
          {deletePending && (
            <CustomCircularProgress
              size={20}
              position="absolute"
              top="20%"
              left="50%"
              color={theme.palette.primary.main}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeteleUserDialog;
