import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
const MobileStatusBar = ({ content }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        sx={{
          borderRadius: "25px",
          margin: "0.5rem",
          fontWeight: "bold",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          border: "solid",
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.dark,
          "& .MuiSvgIcon-root": {
            transform: "translateX(-5px)",
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            "& .MuiSvgIcon-root": {
              transition: "transform 0.2s",
              transform: "translateX(5px)",
            },
          },
        }}
        variant="contained"
        endIcon={<ManageHistoryIcon />}
        onClick={handleClick}
      >
        Zarządzaj instalacją
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {content.map((e, i) => {
          return <MenuItem key={i}>{e}</MenuItem>;
        })}
      </Menu>
    </div>
  );
};
export default MobileStatusBar;
