import AddCircleIcon from "@mui/icons-material/AddCircle";
import BlockIcon from "@mui/icons-material/Block";
import CellWifiIcon from "@mui/icons-material/CellWifi";
import GridOffIcon from "@mui/icons-material/GridOff";
import GridOnIcon from "@mui/icons-material/GridOn";
import { Box, Button, Typography, Divider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import useAxios from "axios-hooks";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { url } from "../constants/urls.js";
import EfficiencyProgressMobile from "./EfficiencyProgressMobile";
import LogoPVFLOW from "../constants/logo-pvflow-small.svg";
import LogoSolarman from "../constants/solarman-2.png";

const cookies = new Cookies();

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    color: theme.palette.text.primary,
    // border:'none',
    // borderRadius:'15px',
    padding: "0",
    // background:theme.palette.primary.dark,
    boxShadow: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accent,
    },
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    // background:theme.palette.primary.dark,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-menuIcon": {
    color: theme.palette.text.primary,
    background: "transparent",
  },
  //   '& .MuiSvgIcon-root':{
  //       color:theme.palette.text.primary,
  //       background:'transparent',
  //   }
}));

const tempData = [
  {
    id: 1,
    group: "Pomorskie",
    pv: "ADVANT 01",
    source: "PV FLOW",
    inverter: "Solis",
    inverterRatedPower: 10000,
    generatorRatedPower: 12000,
    modemConnection: false,
    inverterConnection: false,
    date: 1645090007000,
    efficiency: 58,
    inverter_sn: "501501061920L3100002",
  },
  {
    id: 2,
    group: "Pomorskie",
    pv: "ADVANT 02",
    source: "PV FLOW",
    inverter: "Solis",
    modemConnection: false,
    inverterRatedPower: 20000,
    generatorRatedPower: 21000,
    inverterConnection: false,
    date: 1645090007000,
    efficiency: 75,
    inverter_sn: "501501061920L3100002",
  },
];

const MainTable = ({
  groupSelector,
  group,
  handleClickOpen,
  handleAddPvClickOpen,
  data,
  loading,
  refetch,
  handlePageChange,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [pvs, setPvs] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "status",
      sort: "desc",
    },
  ]);

  React.useEffect(() => {
    forceReload();
  }, [group]);

  React.useEffect(() => {
    handlePageChange(page + 1);
  }, [page]);

  React.useEffect(() => {
    if (data && data.results) {
      setPvs(data.results);
    }
  }, [data]);

  const forceReload = () => {
    setPvs([]);
    setPage(0);
    if (group && group.id) {
      refetch();
    }
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    // const page = useGridSelector(apiRef, gridPageSelector);
    // const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageCount = Math.ceil(group.number_of_pvs / 10);

    return (
      <Pagination
        sx={{
          color: theme.palette.text.tertiary,
          "& .MuiButtonBase-root": {
            // backgroundColor:theme.palette.primary.accent,
            color: theme.palette.text.tertiary,
            borderColor: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.accent,
            "&:hover": {
              backgroundColor: theme.palette.primary.accentActive,
            },
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.accentActive,
            "&.Mui-focusVisible": {
              background: theme.palette.primary.accentActive,
            },
          },
        }}
        // color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => setPage(value - 1)}
      />
    );
  };
  const useStyles = makeStyles({
    root: {
      "& .header": {
        color: theme.palette.text.primary,
        //   background:theme.palette.primary.dark,
        "& > .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "& > .MuiDataGrid-menuIcon": {
          // background:theme.palette.primary.dark,
          color: theme.palette.text.primary,
        },
      },
    },
  });
  const classes = useStyles();

  const columns = [
    {
      field: "description",
      headerName: "Instalacja",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "sn",
      headerName: "Numer seryjny",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "inverter_rated_power",
      headerName: "Moc falownika [kW]",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return params.formattedValue / 1000;
      },
      flex: 1,
    },
    {
      field: "generator_rated_power",
      headerName: "Moc generatora [kW]",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return params.formattedValue / 1000;
      },
      flex: 1,
    },
    {
      field: "api_type",
      headerName: "Źródło danych",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.formattedValue === "PV") {
          return <img src={LogoPVFLOW} width="30" alt="Logo source" />;
        } else {
          return (
            <img src={LogoSolarman} width="30" alt="Logo source solarman" />
          );
        }
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        //
        if (params.formattedValue === "WORKING") {
          return (
            <GridOnIcon
              sx={{ color: theme.palette.primary.success, fontSize: "1.5rem" }}
            />
          );
        } else {
          if (params.formattedValue === "INVALID MESSAGE") {
            return (
              <GridOffIcon
                sx={{ color: theme.palette.primary.alert, fontSize: "1.5rem" }}
              />
            );
          } else {
            return (
              <BlockIcon
                sx={{ color: theme.palette.primary.alert, fontSize: "1.5rem" }}
              />
            );
          }
        }
      },
      flex: 1,
    },
    {
      field: "max_date",
      headerName: "Ostatni odczyt",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",

      renderCell: (params: GridRenderCellParams) => {
        //
        if (params.formattedValue) {
          return format(new Date(params.formattedValue), "HH:mm dd/MM/yyyy", {
            locale: pl,
          });
        } else {
          return "Brak";
        }
      },
      flex: 1,
    },
    {
      field: "last_power",
      headerName: "Wydajność",
      headerClassName: "header",
      headerAlign: "center",
      align: "center",

      renderCell: (params: GridRenderCellParams) => {
        //
        //

        return (
          <EfficiencyProgressMobile
            type="table"
            efficiency={parseInt(
              (
                (params.formattedValue / params.row.generator_rated_power) *
                100
              ).toFixed(0)
            )}
          />
        );
      },
      flex: 1,
    },
  ];

  // const columns = [
  //   {
  //     field: "pv",
  //     headerName: "Instalacja",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "inverter_sn",
  //     headerName: "Numer seryjny",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "inverterRatedPower",
  //     headerName: "Moc falownika [kW]",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: GridRenderCellParams) => {
  //       return params.formattedValue / 1000;
  //     },
  //     flex: 1,
  //   },
  //   {
  //     field: "generatorRatedPower",
  //     headerName: "Moc generatora [kW]",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: GridRenderCellParams) => {
  //       return params.formattedValue / 1000;
  //     },
  //     flex: 1,
  //   },
  //   {
  //     field: "source",
  //     headerName: "Źródło",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "inverter",
  //     headerName: "Falownik",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "modemConnection",
  //     headerName: "Status modemu",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: GridRenderCellParams) => {
  //       //
  //       if (params.formattedValue === true) {
  //         return (
  //           <CellWifiIcon
  //             sx={{ color: theme.palette.primary.success, fontSize: "1.5rem" }}
  //           />
  //         );
  //       } else {
  //         return (
  //           <BlockIcon
  //             sx={{ color: theme.palette.primary.alert, fontSize: "1.5rem" }}
  //           />
  //         );
  //       }
  //     },
  //     flex: 1,
  //   },
  //   {
  //     field: "inverterConnection",
  //     headerName: "Status falownika",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: GridRenderCellParams) => {
  //       //
  //       if (params.formattedValue === true) {
  //         return (
  //           <GridOnIcon
  //             sx={{ color: theme.palette.primary.success, fontSize: "1.5rem" }}
  //           />
  //         );
  //       } else {
  //         return (
  //           <GridOffIcon
  //             sx={{ color: theme.palette.primary.alert, fontSize: "1.5rem" }}
  //           />
  //         );
  //       }
  //     },
  //     flex: 1,
  //   },
  //   {
  //     field: "date",
  //     headerName: "Ostatni odczyt",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",

  //     renderCell: (params: GridRenderCellParams) => {
  //       //
  //       return format(new Date(params.formattedValue), "HH:mm dd/MM/yyyy", {
  //         locale: pl,
  //       });
  //     },
  //     flex: 1,
  //   },
  //   {
  //     field: "efficiency",
  //     headerName: "Wydajność",
  //     headerClassName: "header",
  //     headerAlign: "center",
  //     align: "center",

  //     renderCell: (params: GridRenderCellParams) => {
  //       //
  //       return (
  //         <EfficiencyProgressMobile
  //           type="table"
  //           efficiency={params.formattedValue}
  //         />
  //       );
  //     },
  //     flex: 1,
  //   },
  // ];

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        background: theme.palette.primary.dark,
        flexDirection: "column",
        boxShadow: "0px 10px 20px -5px #000000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {groupSelector}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleAddPvClickOpen}
            endIcon={<AddCircleIcon />}
            sx={{ marginRight: "45px" }}
          >
            Dodaj instalację do obecnej grupy
          </Button>
          <Button onClick={handleClickOpen} endIcon={<AddCircleIcon />}>
            Dodaj nową grupę
          </Button>
        </Box>
      </Box>
      <div style={{ flexGrow: 1 }}>
        {group && (
          <StyledDataGrid
            components={{
              Pagination: CustomPagination,
            }}
            onRowClick={(row) => {
              navigate(`/pv/${row.row.sn}`);
            }}
            // sx={{
            //   backgroundColor:'transparent',
            //   // border:'none',

            //   '& .cell':{
            //     color:theme.palette.text.primary,
            //     fontSize:'1rem',
            //   },
            //   '& .alert':{
            //     color:theme.palette.primary.alert,
            //     fontSize:'1rem',
            //   }
            // }}
            // getCellClassName={(params)=>{
            //   if (params.field === 'deviation') {
            //     return 'alert'
            //   }
            //   else {

            //     return 'cell'
            //   }
            // }}
            // disableSelectionOnClick={true}
            hideFooterSelectedRowCount={true}
            className={classes.root}
            pageSize={10}
            rowsPerPageOptions={[10]}
            pagination
            disableExtendRowFullWidth={false}
            rows={pvs}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            // autoHeight={true}
            columns={columns}
            loading={loading}
          />
        )}
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "0.2rem",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h3">Falownik online</Typography>
          <GridOnIcon
            sx={{ color: theme.palette.primary.success, paddingLeft: "0.5rem" }}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h3">Falownik offline</Typography>
          <GridOffIcon
            sx={{ color: theme.palette.primary.alert, paddingLeft: "0.5rem" }}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h3">Brak komunikacji</Typography>
          <BlockIcon
            sx={{ color: theme.palette.primary.alert, paddingLeft: "0.5rem" }}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h3">Chmura PV COMPLEX</Typography>
          <img
            src={LogoPVFLOW}
            width="25"
            alt="Logo source"
            style={{ paddingLeft: "0.5rem" }}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h3">Chmura Solarman</Typography>
          <img
            src={LogoSolarman}
            width="25"
            alt="Logo source solarman"
            style={{ paddingLeft: "0.5rem" }}
          />
        </Box>
      </Box>
    </div>
  );
};
export default MainTable;
