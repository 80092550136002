import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { linearProgressClasses } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: theme.palette.primary.success,
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        {props.type !== "table" && (
          <Typography variant="h2">Wydajność</Typography>
        )}
        <BorderLinearProgress
          variant="determinate"
          sx={{ height: "1rem" }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="h2" sx={{ margin: 0, padding: 0 }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  type: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ efficiency, type }) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel type={type} value={efficiency} />
    </Box>
  );
}
