import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { InputAdornment } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import GetAvailableModems from "../api/GetAvailableModems";
import GetPVGroup from "../api/GetPVGroup";
import GetVendors from "../api/GetVendors";
import PostPV from "../api/PostPv";
import PutPV from "../api/PutPV";
import BasicSelect from "./BasicSelect";
const drawerWidth = 450;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AddPVDialog = ({
  openDrawer,
  handleDrawerClose,
  pv,
  handleSnackBarText,
  handleOpenSnackBar,
  addingMode,
  currentGroup,
  isOpen,
  refetch,
}) => {
  const theme = useTheme();
  const [vendors, setVendors] = React.useState(``);
  const [reqFailCode, setReqFailCode] = React.useState(0);
  const [formData, setFormData] = React.useState({
    description: pv ? pv.description : "",
    sn: pv ? pv.sn : "",
    address_street: pv ? pv.address_street : "",
    address_number: pv ? pv.address_number : "",
    address_number_2: pv ? pv.address_number_2 : "",
    address_post_code: pv ? pv.address_post_code : "",
    address_city: pv ? pv.address_city : "",
    inverter_rated_power: pv ? pv.inverter_rated_power : "",
    generator_rated_power: pv ? pv.generator_rated_power : "",
    longitude: pv ? pv.longitude : "",
    latitude: pv ? pv.latitude : "",
    azimuth: pv ? pv.azimuth : "",
    elevation: pv ? pv.elevation : "",
    pv_vendor: pv ? pv.pv_vendor : "",
    pv_modem: pv ? pv.pv_modem : "",
    group: pv ? pv.group : "",
  });
  const [expanded, setExpanded] = React.useState(false);
  const [availableModems, setAvailableModems] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [chosenGroup, setChosenGroup] = React.useState(null);
  const [chosenModem, setChosenModem] = React.useState(null);
  const [isModemEmpty, setIsModemEmpty] = React.useState(false);
  const [isVendorEmpty, setIsVendorEmpty] = React.useState(false);
  const [isGroupEmpty, setIsGroupEmpty] = React.useState(false);
  const [pvAlreadyExist, setPvAlreadyExist] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    reloadModems();

    GetPVGroup()
      .then((res) => {
        if (res && res.data) {
          setGroups(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        console.log("finalowo");
      });
  }, []);

  React.useEffect(() => {
    if (!addingMode) {
      if (formData.group) {
        if (groups) {
          const group = groups.find((g) => g.id === formData.group);
          if (group) {
            setChosenGroup(group);
            formData.group = group.id;
          }
        }
      }
    } else if (currentGroup) {
      formData.group = currentGroup.id;
    }
  }, [currentGroup, groups]);

  React.useEffect(() => {
    const handleGetVendors = () => {
      GetVendors(formData)
        .then((res) => {
          if (res && res.data) {
            setVendors(res.data);
          }
        })
        .catch((err) => {
          alert("Błąd podczas pobierania modemów.");
        })
        .finally(() => {});
    };
    handleGetVendors();
  }, [pv]);

  const handleEditPV = async () => {
    console.log("banan3");
    setReqFailCode(0);
    setIsModemEmpty(false);
    setIsVendorEmpty(false);
    setIsGroupEmpty(false);
    if (chosenGroup && formData.pv_vendor) {
      if (formData) {
        PutPV(pv.sn, formData)
          .then((res) => {
            if (res && res.data) {
              handleSnackBarText("Zmiany zapisane pomyślnie");
              handleOpenSnackBar();
              handleDrawerClose();
            }
          })
          .catch((err) => {
            setReqFailCode(err.response.status);
          })
          .finally(() => {});
      }
    } else {
      console.log("banann");
      if (!chosenGroup) {
        setIsGroupEmpty(true);
      } else if (!formData.pv_vendor) {
        setIsVendorEmpty(true);
      }
    }
  };

  const handleAddPv = async () => {
    if (addingMode) {
      setReqFailCode(0);
      setIsModemEmpty(false);
      setIsVendorEmpty(false);
      setIsGroupEmpty(false);
      if (chosenModem && formData.pv_vendor) {
        if (formData) {
          formData.azimuth =
            formData.azimuth !== null && formData.azimuth !== ""
              ? formData.azimuth
              : null;
          formData.elevation =
            formData.elevation !== null && formData.elevation !== ""
              ? formData.elevation
              : null;
          formData.latitude =
            formData.latitude !== null && formData.latitude !== ""
              ? formData.latitude
              : null;
          formData.longitude =
            formData.longitude !== null && formData.longitude !== ""
              ? formData.longitude
              : null;
          PostPV(formData)
            .then((res) => {
              if (res && res.data) {
                refetch();
                handleSnackBarText("Instalacje dodana pomyslnie");
                handleOpenSnackBar();
                handleDrawerClose();
                reloadModems();
                setChosenModem(null);
                clearForm();
                setExpanded(false);
              }
            })
            .catch((err) => {
              if (err && err.response) {
                if (err.response.data) {
                  err.response.data.forEach((e) => {
                    setPvAlreadyExist(false);
                    if (e === "PV already exists") {
                      setPvAlreadyExist(true);
                    }
                  });
                }
                if (err.response.status) {
                  setReqFailCode(err.response.status);
                }
              }
            })
            .finally(() => {});
        }
      } else {
        if (!chosenModem) {
          setIsModemEmpty(true);
        } else if (!formData.pv_vendor) {
          setIsVendorEmpty(true);
        }
      }
    }
  };

  const reloadModems = () => {
    GetAvailableModems()
      .then((res) => {
        if (res && res.data) {
          setAvailableModems(
            res.data.map((m) => {
              m.name = m.barcode;
              return m;
            })
          );
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const clearForm = () => {
    setFormData({
      description: "",
      sn: "",
      address_street: "",
      address_number: "",
      address_number_2: "",
      address_post_code: "",
      address_city: "",
      inverter_rated_power: "",
      generator_rated_power: "",
      longitude: "",
      latitude: "",
      azimuth: "",
      elevation: "",
      pv_vendor: "",
      pv_modem: "",
      group: "",
    });
  };

  const handleStringInput = (event) => {
    event.preventDefault();
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeVendor = (e) => {
    e.preventDefault();

    setFormData({ ...formData, pv_vendor: e.target.value });
  };

  const handleGroupChange = (e) => {
    if (groups) {
      const group = groups.find((g) => g.name === e.target.value.name);
      if (group) {
        setChosenGroup(group);
        formData.group = group.id;
      }
    }
  };

  const tmpFoo = () => {
    console.log("se kliknalem", isOpen);
    // handleDrawerClose();
  };

  const handleModemChange = (e) => {
    if (availableModems) {
      const modem = availableModems.find(
        (g) => g.barcode === e.target.value.barcode
      );
      if (modem) {
        setChosenModem(modem);
        formData.pv_modem = modem.barcode;
      }
    }
  };

  return (
    <Box
      sx={
        openDrawer
          ? {
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.3)",
            }
          : {}
      }
      onClick={handleDrawerClose}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDrawer}
        onClick={(event) => {
          if (!event) var event = window.event;
          event.cancelBubble = true;
          if (event.stopPropagation) event.stopPropagation();
        }}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">
            {addingMode ? "Dodaj instalację do grupy" : "Edytuj instalację"}
          </Typography>
          <Button onClick={handleDrawerClose}>
            <Typography variant="h3">Anuluj</Typography>

            <ChevronRightIcon />
          </Button>
        </DrawerHeader>
        <Divider
          fullWidth
          flexItem={true}
          orientation="horizontal"
          sx={{
            backgroundColor: theme.palette.primary.accent,
            height: "4px",
          }}
        />
        <Box
          component="form"
          autoComplete="off"
          sx={{
            //   width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1rem",
          }}
        >
          <Box sx={{ marginBottom: 2 }}>
            {addingMode && availableModems ? (
              <>
                <span>Modem:</span>
                <BasicSelect
                  handleChange={handleModemChange}
                  val={
                    chosenModem
                      ? { barcode: chosenModem.barcode }
                      : { barcode: "-" }
                  }
                  array={availableModems}
                  required
                  type="modem"
                />
              </>
            ) : (
              <></>
            )}
          </Box>
          {!addingMode || chosenModem ? (
            <>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography variant="h2">Dane techniczne</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    //   label="Nazwa instalacji"
                    label="Nazwa instalacji"
                    variant="outlined"
                    fullWidth
                    required
                    name="description"
                    helperText="Nadaj nazwę własną, która pozwoli Ci ją później zidentyfikować"
                    value={formData.description}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                      sx={{ color: theme.palette.text.secondary }}
                    /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {vendors && (
                    <BasicSelect
                      handleChange={handleChangeVendor}
                      val={{ name: formData.pv_vendor }}
                      array={vendors}
                      type="vendor"
                    />
                  )}
                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    label="Numer seryjny falownika"
                    variant="outlined"
                    fullWidth
                    required
                    name="sn"
                    helperText=""
                    value={formData.sn}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    label="Moc znamionowa falownika"
                    variant="outlined"
                    fullWidth
                    required={true}
                    name="inverter_rated_power"
                    helperText="Pamiętaj, aby wartość podać w watach"
                    value={formData.inverter_rated_power}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    label="Moc znamionowa generatora"
                    variant="outlined"
                    fullWidth
                    required={true}
                    name="generator_rated_power"
                    helperText="Suma mocy zamontowanych paneli, podawana w Wp"
                    value={formData.generator_rated_power}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography variant="h2">Adres</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      sx={{ margin: "0.5rem" }}
                      id="outlined-basic"
                      label="Miasto"
                      variant="outlined"
                      fullWidth
                      required={true}
                      name="address_city"
                      helperText=""
                      value={formData.address_city}
                      onChange={handleStringInput}
                      InputProps={{
                        sx: { borderRadius: "25px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      sx={{ margin: "0.5rem" }}
                      id="outlined-basic"
                      label="Kod pocztowy"
                      variant="outlined"
                      fullWidth
                      required={true}
                      name="address_post_code"
                      helperText=""
                      value={formData.address_post_code}
                      onChange={handleStringInput}
                      InputProps={{
                        sx: { borderRadius: "25px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                  /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    label="Ulica"
                    variant="outlined"
                    fullWidth
                    required={true}
                    name="address_street"
                    helperText=""
                    value={formData.address_street}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      sx={{ margin: "0.5rem" }}
                      id="outlined-basic"
                      label="Numer budynku"
                      variant="outlined"
                      fullWidth
                      required={true}
                      name="address_number"
                      helperText=""
                      type="number"
                      value={formData.address_number}
                      onChange={handleStringInput}
                      InputProps={{
                        sx: { borderRadius: "25px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      sx={{ margin: "0.5rem" }}
                      id="outlined-basic"
                      label="Numer lokalu"
                      type="number"
                      variant="outlined"
                      fullWidth
                      required={false}
                      name="address_number_2"
                      helperText=""
                      value={formData.address_number_2}
                      onChange={handleStringInput}
                      InputProps={{
                        sx: { borderRadius: "25px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{ marginBottom: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography variant="h2">Położenie</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      sx={{ margin: "0.5rem" }}
                      id="outlined-basic"
                      label="Azymut"
                      type="number"
                      variant="outlined"
                      fullWidth
                      required={false}
                      name="azimuth"
                      helperText=""
                      value={formData.azimuth}
                      onChange={handleStringInput}
                      InputProps={{
                        sx: { borderRadius: "25px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      sx={{ margin: "0.5rem" }}
                      id="outlined-basic"
                      label="Nachylenie"
                      type="number"
                      variant="outlined"
                      fullWidth
                      required={false}
                      name="elevation"
                      helperText=""
                      value={formData.elevation}
                      onChange={handleStringInput}
                      InputProps={{
                        sx: { borderRadius: "25px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    label="Długość geograficzna"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required={false}
                    name="longitude"
                    helperText=""
                    value={formData.longitude}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={{ margin: "0.5rem" }}
                    id="outlined-basic"
                    label="Szerokość geograficzna"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required={false}
                    name="latitude"
                    helperText=""
                    value={formData.latitude}
                    onChange={handleStringInput}
                    InputProps={{
                      sx: { borderRadius: "25px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AlternateEmailIcon
                    sx={{ color: theme.palette.text.secondary }}
                /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              {!addingMode && chosenGroup ? (
                <>
                  <span>Grupa:</span>
                  <BasicSelect
                    handleChange={handleGroupChange}
                    val={chosenGroup ? chosenGroup : { name: "-" }}
                    array={groups}
                    required
                    type="group"
                  />
                </>
              ) : (
                <></>
              )}
              <Button
                sx={{
                  borderRadius: "25px",
                  margin: "0.5rem",
                  fontWeight: "bold",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  border: "solid",
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  backgroundColor: "transparent",
                  "& .MuiSvgIcon-root": {
                    transform: "translateX(-5px)",
                  },
                  "&:hover": {
                    backgroundColor: "transparent",
                    "& .MuiSvgIcon-root": {
                      transition: "transform 0.2s",
                      transform: "translateX(5px)",
                    },
                  },
                }}
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  if (addingMode) {
                    handleAddPv();
                  } else {
                    handleEditPV();
                  }
                }}
                type="submit"
              >
                Zapisz
              </Button>

              <Box style={{ marginTop: 10 }}>
                {reqFailCode === 0 ? (
                  isGroupEmpty ? (
                    <span style={{ color: theme.palette.primary.yellow }}>
                      Należy wybrać grupę
                    </span>
                  ) : isModemEmpty ? (
                    <span style={{ color: theme.palette.primary.yellow }}>
                      Należy wybrać modem
                    </span>
                  ) : (
                    <></>
                  )
                ) : reqFailCode === 400 ? (
                  pvAlreadyExist ? (
                    <span style={{ color: theme.palette.primary.alert }}>
                      Taka instalacja już istnieje
                    </span>
                  ) : (
                    <span style={{ color: theme.palette.primary.alert }}>
                      Niepoprawne dane wejściowe
                    </span>
                  )
                ) : reqFailCode >= 500 && reqFailCode < 600 ? (
                  <span style={{ color: theme.palette.primary.alert }}>
                    Błąd po stronie serwera
                  </span>
                ) : (
                  <span style={{ color: theme.palette.primary.alert }}>
                    Niestandardowy błąd operacji na instalacji
                  </span>
                )}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};
export default AddPVDialog;
