import { api } from "./../funcs/AuthManager";

async function GetPVStatus(pv_id) {
  return api
    .get(`/api/v1/pv_status/${pv_id}/`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default GetPVStatus;
