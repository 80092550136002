import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.accent,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));

export default function RowRadioButtonsGroup({
  handleChangeRadio,
  value,
  view,
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Ujęcie czasu</FormLabel>
      <RadioGroup
        row
        aria-label="date-range"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChangeRadio}
      >
        <FormControlLabel value="day" control={<StyledRadio />} label="Dzień" />
        <FormControlLabel
          value="week"
          control={<StyledRadio />}
          label="Tydzień"
          disabled={view !== "energy" && view !== "voltage"}
        />
        <FormControlLabel
          value="month"
          control={<StyledRadio />}
          label="Miesiąc"
          disabled={view !== "energy"}
        />
        <FormControlLabel
          value="year"
          control={<StyledRadio />}
          label="Rok"
          disabled={view !== "energy"}
        />
      </RadioGroup>
    </FormControl>
  );
}
