import { useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { useAuth } from "../funcs/useAuth";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import PostPasswordReset from "../api/PostPasswordReset";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Typography } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
const StyledGridItem = styled(Grid)(
  ({ theme, alignitems, justifycontent, height, flexdirection }) => ({
    display: "flex",
    alignItems: alignitems,
    justifyContent: justifycontent,
    flexDirection: flexdirection,
    height: height,
    flexGrow: 1,
  })
);

const ForgotPasswordPage = () => {
  const [email, setEmail] = React.useState("");
  const [authFailCode, setAuthFailCode] = React.useState(0);
  const [isEmailEmpty, setIsEmailEmpty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setAuthFailCode(0);
    setIsEmailEmpty(false);
    if (email === "") {
      setIsEmailEmpty(true);
    } else {
      setIsLoading(true);
      PostPasswordReset(email)
        .then((res) => {})
        .catch((err) => {
          setAuthFailCode(err.response.status);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEnterPressed = (event) => {
    if (event.keyCode === 13) {
      // enter keyCode
      handlePasswordReset();
    }
  };

  const handleGoBack = (event) => {
    navigate("/login");
  };

  return (
    <StyledGridItem
      container
      height="100vh"
      width="100vw"
      justifyContent="space-between"
      sx={{ backgroundColor: theme.palette.primary.main }}
      spacing={0}
    >
      <StyledGridItem
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
        height="100vh"
        flexdirection="column"
        alignItems="center"
        spacing={0}
        sx={{
          // backgroundImage: `url(${SolarPanelBg})`,
          backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",

          backgroundPositionY: "50%",
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            color: theme.palette.text.tertiary,
            "&:hover": {
              fontWeight: "bold",
            },
          }}
          onClick={handleGoBack}
        >
          Powrót do strony logowania
        </Button>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <Typography
            variant="h1"
            sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            Zapomniales hasla?
          </Typography>

          <span>
            Na podany email zostanie wysłany link do ustawienia nowego hasła
          </span>
          <FormControl fullWidth sx={{ padding: "2rem", width: "20rem" }}>
            <TextField
              id="outlined-basic"
              placeholder="Email"
              onKeyDown={handleEnterPressed}
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              InputProps={{
                sx: { borderRadius: "25px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              sx={{
                borderRadius: "25px",
                margin: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                "& .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  "& .MuiSvgIcon-root": {
                    transition: "transform 0.2s",
                    transform: "translateX(5px)",
                  },
                },
              }}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handlePasswordReset}
            >
              WYŚLIJ
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </FormControl>
          {authFailCode === 0 ? (
            <></>
          ) : authFailCode === 400 ? (
            <span
              style={{
                color: theme.palette.primary.alert,
                textAlign: "center",
              }}
            >
              Email został podany w nieodpowiednim formacie
            </span>
          ) : authFailCode >= 500 && authFailCode < 600 ? (
            <span
              style={{
                color: theme.palette.primary.alert,
                textAlign: "center",
              }}
            >
              Błąd po stronie serwera
            </span>
          ) : (
            <span
              style={{
                color: theme.palette.primary.alert,
                textAlign: "center",
              }}
            >
              Niestandardowy błąd
            </span>
          )}
        </Paper>
      </StyledGridItem>
    </StyledGridItem>
  );
};
export default ForgotPasswordPage;
