import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MobileMenu = ({ open, handleClose, anchorEl, handleLogout, content }) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {content.map((e, i) => {
        return <MenuItem key={i}>{e}</MenuItem>;
      })}
      <MenuItem
        sx={{ paddingLeft: "2rem", paddingRight: "1rem" }}
        onClick={handleLogout}
      >
        Wyloguj
      </MenuItem>
    </Menu>
  );
};
export default MobileMenu;
